import { useDispatch, useSelector } from "react-redux";
import { fetchYoiGrow } from "app/redux/states/dashboard/growth";
import { useEffect } from "react";
import useAuth from "app/hooks/useAuth";
const useGrowth = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.growth.fetchStatus);
  const growth = useSelector((state) => state.growth.growth);
  const { user } = useAuth();
  const getGrowth = (username) => {
    dispatch(fetchYoiGrow(username));
  };
  useEffect(() => {
    if (fetchStatus.fulfilled) return;
    getGrowth(user.username);
  }, []);
  return {
    getGrowth,
    growth,
    fetchStatus,
  };
};

export default useGrowth;
