import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	getLineChartData,
	processSocket,
} from "app/redux/states/metrics/linechart";
import { useAuth, useChartSocket } from "app/hooks";

const useLineChart = () => {
	const dispatch = useDispatch();
	const { user } = useAuth();
	const fetchStatus = useSelector((state) => state.lineChart.fetchStatus);
	const { lastJsonMessage, readyState } = useChartSocket();
	const supportGroup = useSelector(
		(state) => state.lineChart.lineChart.supportGroup
	);
	const dailyTheme = useSelector(
		(state) => state.lineChart.lineChart.dailyTheme
	);
	const suggestion = useSelector(
		(state) => state.lineChart.lineChart.suggestion
	);
	const fetchLineChartData = () => {
		dispatch(getLineChartData({ username: user.username, unit: "day" }));
	};

	useEffect(() => {
		if (fetchStatus.fulfilled) return;
		fetchLineChartData();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		dispatch(processSocket(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	return {
		fetchLineChartData,
		supportGroup,
		dailyTheme,
		suggestion,
	};
};

export default useLineChart;
