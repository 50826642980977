import React, { useEffect } from "react";
import useSocketData from "./useSocketData";
import axios from "axios.js";

function removeDuplicates(arr) {
  const strigified = arr.map((item) => JSON.stringify(item));
  const removed = [...new Set(strigified)];
  return removed.map((item) => JSON.parse(item));
}

const getUsers = (messages, user) => {
  const users = messages.map((message) => {
    return message.posted_by_user;
  });
  // Remove current user from array and remove repeated users
  const guestUsers = users.filter(
    (messages) => messages.username !== user.username
  );
  console.log("LISTA DE USERS: ", removeDuplicates(guestUsers))
  return removeDuplicates(guestUsers);
};

const handleMsg = (messages, newMsg) => {
  if (newMsg.reply_to_message) {
    let replied = messages.find(
      (message) => message.id === newMsg.reply_to_message
    );

    let newReplied = {
      ...replied,
      top_replies: [newMsg, ...replied.top_replies],
    };

    let newArray = messages.map((message) => {
      if (message.id === newMsg.reply_to_message) {
        return newReplied;
      } else {
        return message;
      }
    });

    return removeDuplicates(newArray);
  } else {
    let newMsgs = [...messages, { ...newMsg, top_replies: [] }];

    return removeDuplicates(newMsgs);
  }
};

const handleReply = async (messages, id) => {
  let messageReplies = messages.find((message) => message.id === id);

  const response = await axios.get(
    messageReplies.next
      ? `/chat/api/message/${id}/replies/?page=${messageReplies.next}`
      : `/chat/api/message/${id}/replies/`
  );

  let allReplies = [...messageReplies.top_replies, ...response.data.results];

  messageReplies = {
    ...messageReplies,
    next: response.data.next,
    top_replies: removeDuplicates(allReplies),
  };

  let newMessageList = messages.map((message) => {
    if (message.id !== id) {
      return message;
    } else {
      return messageReplies;
    }
  });
  // state.replyStatus = { id: null, loading: false };
  return newMessageList;
};

const loadConversationReplies = async (id, next) => {
  const response = await axios.get(
    `/chat/api/conversation/${id}/messages/?page=${next}&`
  );
  const reversedMessages = response.data.results.reverse();
  return { messages: reversedMessages, next: response.data.next };
};
const getChatUsers = (user, messages) => {
  const users = messages.map((message) => {
    return message.posted_by_user;
  });
  // Remove current user from array and remove repeated users
  const guestUsers = users.filter(
    (messages) => messages.username !== user.username
  );
  return removeDuplicates(guestUsers);
};

const useChat = (conversationId) => {
  const { lastJsonMessage, readyState } = useSocketData(
    `/ws/chat/${conversationId}/`
  );
  return {
    lastJsonMessage,
    readyState,
    handleMsg,
    handleReply,
    loadConversationReplies,
    getChatUsers,
    getUsers,
  };
};

export default useChat;
