import { Grid, InputAdornment, Typography, Box, Divider } from "@mui/material";
import SearchInput from "../SearchInput";
import { Search } from "@mui/icons-material";
import { useLang } from "app/hooks";
import { routeNames } from "routes/routeIndex";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SearchBar = ({
	extraConfigs = {},
	setSearchParams,
	autocomplete = [],
	searchParams,
	searchType,
}) => {
	const navigate = useNavigate();
	const [autoList, setAutoList] = useState([]);
	const { content } = useLang();
	const searchBarContent = content?.details.searchBar;
	const defaultConfigs = {
		container: {
			justifyContent: "flex-end",
		},
		inputs: [
			{
				key: "created_at_after",
				size: 2.5,
				label: searchBarContent.after,
				type: "date",
			},
			{
				key: "created_at_before",
				size: 2.5,
				label: searchBarContent.before,
				type: "date",
			},
			{
				key: "search",
				size: 4,
				label: searchBarContent.search,
				type: "text",
				InputProps: {
					startAdornment: (
						<InputAdornment position='start'>
							<Search />
						</InputAdornment>
					),
				},
			},
		],
	};
	const configs = { ...defaultConfigs, ...extraConfigs };
	const { inputs, container } = configs;
	const setSearched = () => {
		if (searchParams?.search === "" || searchParams?.name === "") {
			setAutoList([]);
			return;
		}
		if (searchType === "forums") {
			setAutoList(
				autocomplete.filter((data) =>
					data.message_content.includes(searchParams.search)
				)
			);
			return;
		}
		if (searchType === "supportGroup") {
			setAutoList(
				autocomplete.filter((data) => data.name.includes(searchParams.name))
			);
			return;
		}
		if (searchType === "suggestion") {
			setAutoList(
				autocomplete.filter((data) => data.title.includes(searchParams.search))
			);
			return;
		}
	};

	const route = (data) => {
		return searchType === "suggestion"
			? `${routeNames.yoiDashboard.suggestionDetails}/${data.id}`
			: searchType === "forums"
			? `${routeNames.yoiDashboard.forums}/${data.conversation_id}`
			: searchType === "supportGroup"
			? `${routeNames.yoiDashboard.supportGroups}/${data.conversation}`
			: "/";
	};
	const handleChange = (key, value) => {
		if (!setSearchParams) return;
		setSearchParams((current) => ({ ...current, [key]: value }));
		setSearched();
	};
	useEffect(() => {
		setSearched();
	}, [searchParams]);

	return (
		<Grid container spacing={2} {...container}>
			{inputs.map((input) => {
				const { size, type, label, key, ...others } = input;
				if (type === "text") {
					return (
						<Grid item xs={size} position='relative'>
							<SearchInput
								type={type}
								onChange={(event) => handleChange(key, event.target.value)}
								{...others}
							>
								{label}
							</SearchInput>
							{autoList.length > 0 && (
								<Box
									marginTop={2}
									position='absolute'
									backgroundColor='white'
									left={"0px"}
									zIndex={99}
									width='100%'
									ml={2}
									sx={{
										background: "#FFFFFF",
										/* BUTTON SHADOW */
										boxShadow: "inset 4px 6px 6px -1px rgba(0, 0, 0, 0.25)",
										borderRadius: "10px",
										padding: 1,
									}}
								>
									{autoList.map((data) => (
										<Box
											cursor='pointer'
											my={1}
											sx={{
												":hover": {
													color: "#7814E4",
													cursor: "pointer",
												},
											}}
										>
											<Typography onClick={() => navigate(route(data))}>
												{searchType === "forums"
													? data.message_content
													: searchType === "suggestion"
													? data.title
													: searchType === "supportGroup"
													? data.name
													: ""}
											</Typography>
											<Divider />
										</Box>
									))}
								</Box>
							)}
						</Grid>
					);
				} else {
					return (
						<Grid item xs={size}>
							<SearchInput
								type={type}
								onChange={(event) => handleChange(key, event.target.value)}
								{...others}
							>
								{label}
							</SearchInput>
						</Grid>
					);
				}
			})}
		</Grid>
	);
};

export default SearchBar;
