import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    isOpen: false,
    message: "",
    status: "",
    welcomeMessage: false,
  },
  reducers: {
    handleMessage: (state, action) => {
      const payload = action.payload;
      state.isOpen = true;
      state.message = payload.message;
      state.status = payload.status || null;
    },
    handleClose: (state) => {
      state.isOpen = false;
      state.message = "";
      state.status = "";
    },
    openWelcomeMessage: (state) => {
      state.welcomeMessage = true;
    },
    closeWelcomeMessage: (state) => {
      state.welcomeMessage = false;
    },
  },
});

export const {
  handleMessage,
  handleClose,
  closeWelcomeMessage,
  openWelcomeMessage,
} = notificationSlice.actions;

export default notificationSlice.reducer;
