import Loadable from "app/components/Loadable";
import { routeNames } from "routes/routeIndex.js";
import { lazy } from "react";
import NotFound from "app/views/sessions/NotFound";
import Contact from "./contact/AgentContact";
const Dashboard = Loadable(lazy(() => import("./Dashboard.jsx")));
export const AgentRoutes = [
  { path: routeNames.yoiDashboard.dashboard, element: <Dashboard /> },
  { path: routeNames.yoiDashboard.contact, element: <Contact /> },
  { path: "*", element: <NotFound /> },
];
