import { styled } from "@mui/styles";
import { TextField } from "@mui/material";

const baseStyles = {
  border: "0px !important",
  borderColor: "transparent",
  background: "#FFFFFF",
  /* BUTTON SHADOW */
  boxShadow: "inset 4px 6px 6px -1px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
};

const YoiInput = styled(TextField)({
  ...baseStyles,
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    ...baseStyles,
    "& fieldset": {
      borderColor: "transparent",
      boxShadow: "inset 4px 6px 6px -1px rgba(0, 0, 0, 0.25)",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});
export default YoiInput;
