import React from "react";
import { IconButton, styled } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";

const CustomIcon = styled(IconButton)({
  backgroundColor: "#8C8C8C",
  color: "#DEDEDE",
  ":hover": {
    backgroundColor: "#8C8C8C",
    filter: "brightness(80%)",
  },
});

const ForwardButton = ({ click = () => {}, sx = null }) => {
  return (
    <CustomIcon onClick={click} sx={sx}>
      <ChevronRight />
    </CustomIcon>
  );
};

export default ForwardButton;
