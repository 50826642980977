import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios.js";

export const getSuggestionList = createAsyncThunk(
	"suggestionDetails/getinitial",
	async (data) => {
		const params = {
			type: "cms.SuggestionPage",
			fields: "*",
			...data.queryParams,
		};
		const response = await axios.get(`/cms/api/v2/pages/`, { params });
		return {
			response: response.data.items,
			total: response.data.meta.total_count,
		};
	}
);
function removeDuplicates(arr) {
	const strigified = arr.map((item) => JSON.stringify(item));
	const removed = [...new Set(strigified)];
	return removed.map((item) => JSON.parse(item));
}

const suggestionDetailSlice = createSlice({
	name: "suggestionDetail",
	initialState: {
		data: [],
		offset: 0,
		total: 0,
		fetchStatus: {
			error: false,
			loading: false,
			fulfilled: false,
		},
	},
	reducers: {
		addData: (state, action) => {
			state.data = [...current(state.data), ...action.payload.data];
			state.offset = action.payload.offset;
		},
		addSearchData: (state, action) => {
			let extendedArray = [...current(state.data), ...action.payload.items];
			state.data = removeDuplicates(extendedArray);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSuggestionList.pending, (state, action) => {
			state.fetchStatus.loading = true;
			state.fetchStatus.error = false;
		});
		builder.addCase(getSuggestionList.fulfilled, (state, action) => {
			state.data = action.payload.response;
			state.total = action.payload.total;
			state.offset = action.payload.response.length;
			state.fetchStatus.loading = false;
			state.fetchStatus.fulfilled = true;
		});
		builder.addCase(getSuggestionList.rejected, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.error = true;
		});
	},
});
export const { addData, addSearchData } = suggestionDetailSlice.actions;

export default suggestionDetailSlice.reducer;
