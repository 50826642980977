import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchYoiNotifications,
  processSocketNotification,
} from "app/redux/states/dashboard/yoiNotifications";
import { useSocketData } from "app/hooks";

const useYoiNotifications = () => {
  const dispatch = useDispatch();
  const { loading, error, fulfilled } = useSelector(
    (state) => state.yoiNotifications.fetchStatus
  );

  const notifications = useSelector(
    (state) => state.yoiNotifications.notifications
  );
  const count = useSelector((state) => state.yoiNotifications.count);
  const { lastJsonMessage, readyState } = useSocketData("/ws/notification/");

  const getNotifications = () => {
    dispatch(fetchYoiNotifications());
  };

  useEffect(() => {
    if (!fulfilled) {
      getNotifications();
    }
    if (readyState !== 1 || lastJsonMessage === null) return;
    dispatch(processSocketNotification(lastJsonMessage));
  }, [lastJsonMessage, readyState]);

  return { getNotifications, notifications, count, loading, error };
};

export default useYoiNotifications;
