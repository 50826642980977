import { createSlice, current } from "@reduxjs/toolkit";
import { isArray } from "lodash";

const contactChatSlice = createSlice({
  name: "contactChat",
  initialState: {
    contacts: [],
    currentChat: null,
  },
  reducers: {
    setContacts: (state, action) => {
      const data = action.payload;
      state.contacts = isArray(data) ? [...action.payload] : [data];
    },
    setContactMessages: (state, action) => {
      const { messages, id } = action.payload;
      const contacts = current(state.contacts);

      const newContacts = contacts.map((contact) => {
        if (contact.id === id) {
          state.currentChat = { ...contact, messages: messages };

          return { ...contact, messages: messages };
        }
        return contact;
      });
      state.contacts = newContacts;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    setContactStatus: (state, action) => {
      const { status, id } = action.payload;
      const contacts = current(state.contacts);
      const newContacts = contacts.map((contact) => {
        if (contact.id === id) {
          return { ...contact, isOnline: status };
        }
        return contact;
      });
      state.contacts = newContacts;
    },
    setNewMessage: (state, action) => {
      const { message, id } = action.payload;
      const contacts = current(state.contacts);
      const modifyContact = contacts.find((contact) => contact.id === id);
      if (!modifyContact) {
        console.log("error, Contact not found");
        return;
      }
      // verify if message exists in contact, if it does, replace it
      const messageExists = modifyContact.messages.find(
        (msg) => msg.id === message.id
      );
      let newMessages = [];
      if (messageExists) {
        newMessages = modifyContact.messages.map((msg) => {
          if (msg.id === message.id) {
            return message;
          }
          return msg;
        });
      } else {
        newMessages = [...modifyContact.messages, message];
      }

      if (state.currentChat.id === modifyContact.id) {
        state.currentChat = { ...state.currentChat, messages: newMessages };
      }

      const newContacts = contacts.map((contact) => {
        if (contact.id === id) {
          return { ...contact, messages: newMessages };
        }
        return contact;
      });
      state.contacts = newContacts;
    },
  },
});

export const {
  setContacts,
  setContactMessages,
  setCurrentChat,
  setNewMessage,
  setContactStatus,
} = contactChatSlice.actions;

export default contactChatSlice.reducer;
