import { useRoutes } from "react-router-dom";
import { AgentRoutes as ARoutes, UserRoutes as URoutes } from "app/routes";
import { useAuth } from "app/hooks";
const RouteProvider = () => {
  const { user } = useAuth();
  const UserRoutes = useRoutes(URoutes);
  const AgentRoutes = useRoutes(ARoutes);
  if (user?.role === "referente") {
    return UserRoutes;
  } else {
    return AgentRoutes;
  }
};

export default RouteProvider;
