// import { AuthProvider } from "app/contexts/Auth0Context";
// import { AuthProvider } from 'app/contexts/JWTAuthContext';
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import Store from "./redux/Store";
import LanguageProvider from "languages/LanguageContext";
import ToastProvider from "./toast/ToastProvider";
import RouteProvider from "routes/RouteProvider";
const App = () => {
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <ToastProvider />
          <LanguageProvider>
            <AuthProvider>
              <RouteProvider />
            </AuthProvider>
          </LanguageProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
