import Loadable from "app/components/Loadable";
import { routeNames } from "routes/routeIndex.js";
import { lazy } from "react";

const Dashboard = Loadable(lazy(() => import("./dashboard/Dashboard.jsx")));
const AgentDashboard = Loadable(
  lazy(() => import("./agents/AgentDashboard.jsx"))
);

const Metrics = Loadable(lazy(() => import("./metrics/Metrics")));
const Daily = Loadable(lazy(() => import("./daily-theme/Daily")));
const Suggestions = Loadable(lazy(() => import("./suggestions/Suggestions")));
const SupportGroups = Loadable(
  lazy(() => import("./support-groups/SupportGroups"))
);
const SupportDetails = Loadable(
  lazy(() => import("./support-details/SupportGroup"))
);
const Forums = Loadable(lazy(() => import("./forums/Forums")));
const ForumDetails = Loadable(
  lazy(() => import("./forum-details/ForumDetails"))
);

const SuggestioDetails = Loadable(
  lazy(() => import("./suggestion-details/SDetails"))
);

const Contact = Loadable(lazy(() => import("./contact/Contact")));
const Comunicado = Loadable(lazy(() => import("./comunicados/Comunicados")));

export const YoiRoutes = [
  { path: routeNames.yoiDashboard.dashboard, element: <Dashboard /> },
  { path: routeNames.yoiDashboard.metrics, element: <Metrics /> },
  { path: `${routeNames.yoiDashboard.dailyTheme}/:id`, element: <Daily /> },
  { path: routeNames.yoiDashboard.suggestions, element: <Suggestions /> },
  {
    path: `${routeNames.yoiDashboard.suggestionDetails}/:id`,
    element: <SuggestioDetails />,
  },
  { path: routeNames.yoiDashboard.forums, element: <Forums /> },

  { path: routeNames.yoiDashboard.supportGroups, element: <SupportGroups /> },
  {
    path: `${routeNames.yoiDashboard.supportGroups}/:id`,
    element: <SupportDetails />,
  },
  {
    path: `${routeNames.yoiDashboard.forums}/:id`,
    element: <ForumDetails />,
  },
  {
    path: `${routeNames.yoiDashboard.contact}`,
    element: <Contact />,
  },
  {
    path: `${routeNames.yoiDashboard.notice}`,
    element: <Comunicado />,
  },
];
