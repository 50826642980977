import { nylasAdmin, nylasUser, nylasClient } from "./nylas";

const calendarEndpoint = "/calendars";
const accountEndpoint = `/a/${nylasClient}/accounts/?offset=0&limit=100`;
const eventEndpoint = "/events?calendar_id=";
const defaultCalendar = "6wfhdgra0ag4dggyy4b58cbrq"; // This one gets the venezuelan holidays

export const getCalendars = async () => {
  const response = await nylasUser.get(calendarEndpoint);
  return response.data;
};

export const getAccounts = async (userEmail) => {
  try {
    const accountParams = { metadata_key: "email", metadata_value: userEmail };
    const response = await nylasAdmin.get(accountEndpoint, {
      params: userEmail ? accountParams : {},
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getEvents = async (calendarId = defaultCalendar) => {
  const response = await nylasUser.get(eventEndpoint + calendarId);
  const data = await response.data;
  const newData = data.map((event) => {
    const date = event.when.date;
    return { ...event, date };
  });
  // separate the events by date and return an array of objects with the date and the events for that date as an array of objects as well (the events)
  const eventsByDate = [];
  newData.forEach((event) => {
    const date = event.date;
    // check if date is older than today
    const today = new Date();
    const eventDate = new Date(date);
    if (eventDate < today) return;
    const index = eventsByDate.findIndex((item) => item.date === date);
    if (index === -1) {
      eventsByDate.push({ date, events: [event] });
    } else {
      eventsByDate[index].events.push(event);
    }
  });

  return eventsByDate;
};
