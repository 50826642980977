import { styled, Paper, Box, CircularProgress } from "@mui/material";
import { Text } from "app/components/yoi";
import tc from "thousands-counter";

export const CardContainer = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  padding: 10,
  borderRadius: "10px",
  backgroundColor: "#DEDEDE",
  minWidth: "150px",
  justifyContent: "center",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3);",
});
const defaultImg = "/assets/yoi/default.jpeg";
const ImgIcon = styled("img")({
  width: "45px",
  height: "45px",
  borderRadius: "50px",
});

const Card = ({ src, title, subtitle, count, loading = false, children }) => {
  return (
    <CardContainer>
      <Box display="flex" alignItems="center" justifyContent={"center"} gap={1}>
        <Box>
          <Text
            size="lg"
            fontWeight={700}
            maxHeight="5ch"
            textAlign="center"
            sx={{
              overflow: "hidden",
            }}
          >
            {title}
          </Text>
          {subtitle && <Text sx={{ textAlign: "center" }}>{subtitle}</Text>}
        </Box>
        {src && <Box />}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          color: "#6A6A6A",
        }}
      >
        {loading ? (
          <CircularProgress
            sx={{
              marginY: 1,
              color: "#7814E4",
            }}
          />
        ) : (
          <>
            {children}
            <Text size="super" sx={{ fontWeight: 700 }}>
              {count ? (count > 1000 ? tc(count) : count) : 0}
            </Text>
          </>
        )}
      </Box>
    </CardContainer>
  );
};

export default Card;
