import { useDispatch, useSelector } from "react-redux";
import { fetchDailyTheme } from "app/redux/states/dashboard/daily";
import { useEffect } from "react";
import { useSocketData } from "app/hooks";
import { SOCKET_CASES } from "app/consts/socketCases";
import {
	changeSharedCount,
	changeUserCount,
	changeForumCount,
} from "app/redux/states/dashboard/daily";

const { THEME_CHAT, THEME_FORUM, SHARE } = SOCKET_CASES;

const useDaily = () => {
	const dispatch = useDispatch();
	const fetchStatus = useSelector((state) => state.dailyTheme.fetchStatus);
	const daily = useSelector((state) => state.dailyTheme.dailyTheme);
	const { lastJsonMessage: message, readyState } = useSocketData(
		`/ws/themeoftheday/${daily.id}/`
	);
	const getDailyTheme = () => {
		dispatch(fetchDailyTheme());
	};

	useEffect(() => {
		if (fetchStatus.fulfilled) return;
		getDailyTheme();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || message === null) return;
		if (message.ref === SHARE) return dispatch(changeSharedCount(message));
		if (message.ref === THEME_CHAT) return dispatch(changeUserCount(message));
		if (message.ref === THEME_FORUM) return dispatch(changeForumCount(message));
	}, [message, readyState]);

	return {
		getDailyTheme,
		daily,
		fetchStatus,
	};
};

export default useDaily;
