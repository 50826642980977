import React from "react";
import { Box } from "@mui/material";
import Date from "./Date";
import DateTag from "../DateTag";
const Day = ({ date, appointments }) => {
  return (
    <>
      <Box display="flex" justifyContent="center" my={2}>
        <DateTag date={date} />
      </Box>
      <Box px={2} display="flex" flexDirection="column" gap={2}>
        {appointments.map((appointment, index) => (
          <React.Fragment key={`${appointment.title.split()}-${index}`}>
            <Date time={appointment.time} content={appointment.title} />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default Day;
