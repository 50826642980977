import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios.js";

export const getUserForums = createAsyncThunk(
	"forums/getForums",
	async (data) => {
		const { username } = data;
		const response = await axios.get(
			`/cms/api/v2/extra/themeoftheday-conversation/?referrer=${username}`
		);
		return response.data.results;
	}
);

function removeDuplicates(arr) {
	const strigified = arr.map((item) => JSON.stringify(item));
	const removed = [...new Set(strigified)];
	return removed.map((item) => JSON.parse(item));
}

const forumsSlice = createSlice({
	name: "forums",
	initialState: {
		forums: [],
		fetchStatus: {
			loading: false,
			fulfilled: false,
			error: false,
		},
	},
	reducers: {
		addSearchData: (state, action) => {
			let extendedArray = [...current(state.forums), ...action.payload.items];
			state.forums = removeDuplicates(extendedArray);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserForums.pending, (state, action) => {
			state.fetchStatus.loading = true;
			state.fetchStatus.error = false;
		});
		builder.addCase(getUserForums.fulfilled, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.error = false;
			state.forums = action.payload;

			state.fetchStatus.fulfilled = true;
		});
		builder.addCase(getUserForums.rejected, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.fulfilled = false;
			state.fetchStatus.error = true;
		});
	},
});
export const { addSearchData } = forumsSlice.actions;

export default forumsSlice.reducer;
