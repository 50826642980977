import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { getWeek } from "app/utils/utils";

const CASES = {
  YOI: "user_count",
  USER: "add_user_conversation",
};

let yoiEndpoint = (unit = "week") =>
  `/referrers/api/yoi_user_graph/?format=json&unit=${unit}`;
let userEndpoint = (unit = "week", username) =>
  `/referrers/api/referrers_user_graph/?format=json&unit=${unit}`;
// END HELPER FUNCTIONS

// THUNKS

export const fetchMetrics = createAsyncThunk(
  "metrics/getMetrics",
  async (data) => {
    let yoiResponse = await axios.get(yoiEndpoint(data.unit));
    let userResponse = await axios.get(
      `${userEndpoint(data.unit)}&username=${data.username}`
    );
    return { yoiResponse: yoiResponse.data, userResponse: userResponse.data };
  }
);

export const processSocketMetric = createAsyncThunk(
  "metrics/socketMetric",
  async (metric) => metric
);

// SLICE
const metricsSlice = createSlice({
  name: "metrics",
  initialState: {
    yoiMetrics: [],
    processedMetrics: { yoi: [], user: [] },
    reducedMetrics: { yoi: 0, user: 0 },
    userMetrics: [],
    fetchStatus: {
      loading: false,
      fulfilled: false,
      error: {
        status: false,
        message: "",
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMetrics.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.fetchStatus.fulfilled = false;
      state.fetchStatus.loading = true;
    });
    builder.addCase(fetchMetrics.fulfilled, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = true;

      state.yoiMetrics = action.payload.yoiResponse;
      state.userMetrics = action.payload.userResponse;

      const yoiDates = action.payload.yoiResponse.map((day) => ({
        x: day.x.split("T")[0],
        y: day.y,
      }));
      const userDates = action.payload.userResponse.map((day) => ({
        x: day.x.split("T")[0],
        y: day.y,
      }));

      state.processedMetrics.yoi = getWeek(yoiDates).reverse();
      state.processedMetrics.user = getWeek(userDates).reverse();
    });
    builder.addCase(fetchMetrics.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.error.status = true;
    });
    builder.addCase(processSocketMetric.fulfilled, (state, action) => {
      if (action.payload.ref === CASES.YOI) {
        const lastMetric = state.processedMetrics.yoi.pop();
        state.processedMetrics.yoi.push({
          ...lastMetric,
          y: lastMetric.y + action.payload.y,
        });
      }
      if (action.payload.ref === CASES.USER) {
        const lastMetric = state.processedMetrics.user.pop();
        state.processedMetrics.user.push({
          ...lastMetric,
          y: lastMetric.y + action.payload.y,
        });
      }
    });
  },
});

export const { getWeeklyMetrics, reducedMetrics } = metricsSlice.actions;

export default metricsSlice.reducer;
