import { Typography } from "@mui/material";
const Text = ({ size = "sm", children, ...props }) => {
  const fontSizes = {
    sm: "9px",
    md: "11px",
    lg: "12px",
    xl: "16px",
    title: "24px",
    super: "36px",
  };
  return (
    <Typography fontSize={fontSizes[size]} color="#6a6a6a" {...props}>
      {children}
    </Typography>
  );
};

export default Text;
