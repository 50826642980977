import { Typography, Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import { YoiButton } from "app/components/yoi";
import { useNavigate } from "react-router-dom";

const NotFoundImg = styled("img")({});

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const NFTitle = styled(Typography)(() => ({
  fontSize: "50px",
  fontWeight: 700,
  color: "#F81E38",
}));

const NotFoundRoot = styled(FlexBox)(() => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh !important",
}));

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundRoot>
      <Grid container width="100%" justifyContent={"center"}>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          gap={1}
          sm={6}
          lg={3}
          maxWidth="80%"
          textAlign="center"
        >
          <NotFoundImg src="/assets/yoi/404.png" alt="404 Page not found" />
          <NFTitle>OOPS!</NFTitle>
          <NFTitle>PÁGINA NO ENCONTRADA!</NFTitle>
          <Typography textAlign={"center"}>
            ¿No era por aquí? ¿Qué tal si lo volvemos intentar?
          </Typography>
          <YoiButton onClick={() => navigate(-1)}>Regresar</YoiButton>
        </Grid>
      </Grid>
    </NotFoundRoot>
  );
};

export default NotFound;
