import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios.js";

function removeDuplicates(arr) {
  return [...new Set(arr)];
}

export const getNewData = createAsyncThunk("new/messages", async (data) => {
  const response = await axios.get(
    `/chat/api/conversation/${data.id}/messages/?page=${data.next}`
  );
  return { messages: response.data.results, next: response.data.next };
});

export const getChatData = createAsyncThunk("chat/", async (data) => {
  const response = await axios.get(
    `/cms/api/v2/extra/themeoftheday-conversation/${data.id}/`
  );

  if (!response.status === 200) return;
  const userConversation = response.data.results.find(
    (conversation) => conversation.user.username === data.username
  );
  if (!userConversation) return { conversation: null };
  const conversationResponse = await axios.get(
    `/chat/api/conversation/${userConversation.conversation_id}/messages/`
  );
  if (!conversationResponse.status === 200) return { conversation: null };

  return {
    next: conversationResponse.data.next,
    conversation: userConversation,
    username: data.username,
    messages: conversationResponse.data.results,
  };
  // return { results: await response.data.results, username: data.username };
});

const dailyChatSlice = createSlice({
  name: "dailyChat",
  initialState: {
    userHasChat: false,
    fetchStatus: {
      fulfilled: false,
      error: false,
      loading: false,
    },
    replyStatus: {
      id: "",
      loading: false,
    },
    conversation: {},
    next: 0,
    messages: [],
    userList: [],
  },
  reducers: {
    addChatMessage: (state, action) => {
      try {
        if (action.payload.reply_to_message) {
          const messages = state.messages;
          let replied = messages.find(
            (message) => message.id === action.payload.reply_to_message
          );
          let newReplied = {
            ...current(replied),
            top_replies: [action.payload, ...current(replied.top_replies)],
          };
          let newArray = messages.map((message) => {
            if (message.id === action.payload.reply_to_message) {
              return newReplied;
            } else {
              return message;
            }
          });
          let stringyfiedReplies = newArray.map((message) =>
            JSON.stringify(message)
          );
          let unrepeatedMsgs = removeDuplicates(stringyfiedReplies);

          state.messages = unrepeatedMsgs.map((message) => JSON.parse(message));
        } else {
          let stringfiedMsgs = [
            ...state.messages,
            { ...action.payload, top_replies: [] },
          ].map((message) => JSON.stringify(message));
          let unrepeatedMsgs = removeDuplicates(stringfiedMsgs);
          state.messages = unrepeatedMsgs.map((message) => JSON.parse(message));
        }
      } catch (error) {
        console.log("ERROR: ", error);
      }
    },
    addUserToConversation: (state, action) => {
      state.userList = [...state.userList, action.payload];
    },
    setNext: (state, action) => {
      state.next = action.payload.next;
    },
    setStart: (state, action) => {
      state.replyStatus.loading = action.payload.loading;
      state.replyStatus.id = action.payload.id;
    },
    loadReplies: (state, action) => {
      let messageReplies = current(
        state.messages.find((message) => message.id === action.payload.id)
      );
      let allReplies = [
        ...messageReplies.top_replies,
        ...action.payload.response,
      ];
      let stringedReplies = allReplies.map((reply) => JSON.stringify(reply));
      let removeRepeated = removeDuplicates(stringedReplies);
      let jsonifiedReplies = removeRepeated.map((reply) => JSON.parse(reply));
      messageReplies = {
        ...messageReplies,
        next: action.payload.next,
        top_replies: jsonifiedReplies,
      };

      let newMessageList = state.messages.map((message) => {
        if (message.id !== action.payload.id) {
          return message;
        } else {
          return messageReplies;
        }
      });
      state.replyStatus = { id: null, loading: false };
      state.messages = newMessageList;
    },
  },
  extraReducers: (builder) => {
    // ------- GET CHAT DATA
    builder.addCase(getChatData.pending, (state, action) => {
      state.fetchStatus.loading = true;
      state.fetchStatus.error = false;
    });
    builder.addCase(getChatData.fulfilled, (state, action) => {
      console.log("AQUI ME LLAME", action);
      state.fetchStatus.fulfilled = true;
      state.fetchStatus.loading = false;
      state.fetchStatus.error = false;
      state.conversation = action.payload.conversation;

      // reverse message list to give correct order
      const messages = action.payload.messages ?? [];
      const reversedMessages = messages.reverse();
      state.messages = reversedMessages;
      // Guet the user list based on messages
      const users = messages.map((message) => {
        return message.posted_by_user;
      });
      // Remove current user from array and remove repeated users
      const guestUsers = users.filter(
        (messages) => messages.username !== action.payload.username
      );
      let stringedUsers = guestUsers.map((user) => JSON.stringify(user));
      let unrepeatedUsers = removeDuplicates(stringedUsers);
      unrepeatedUsers = unrepeatedUsers.map((user) => JSON.parse(user));
      state.userList = unrepeatedUsers;
      state.next = action.payload.next;
    });
    builder.addCase(getChatData.rejected, (state, action) => {
      state.fetchStatus.error = true;
      state.loading = false;
    });
    builder.addCase(getNewData.fulfilled, (state, action) => {
      const messages = action.payload.messages ?? [];
      const reversedMessages = messages.reverse();
      const stateMessages = current(state.messages);
      state.next = action.payload.next;
      state.messages = [...reversedMessages, ...stateMessages];
    });
  },
});

export const {
  addChatMessage,
  addUserToConversation,
  loadReplies,
  setStart,
  setNext,
} = dailyChatSlice.actions;
export default dailyChatSlice.reducer;
