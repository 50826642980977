import React from "react";
import { Box, CircularProgress } from "@mui/material";
import GradientBox from "../GradientBox";
import useFiles from "app/hooks/redux/files/useFiles";
import FileList from "./FileList";

const Wrapper = ({ title, children }) => {
  return (
    <GradientBox title={title}>
      <Box py={3} px={2} height="500px">
        {children}
      </Box>
    </GradientBox>
  );
};

const ErrorMessage = ({ error }) => {
  return (
    <Box textAlign="center" fontWeight={700} fontSize="14px">
      <p>{error}</p>
    </Box>
  );
};

const SharedFiles = () => {
  const { files, loading, error } = useFiles();

  return (
    <Wrapper title="archivos compartidos">
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && files.length === 0 && (
        <ErrorMessage error={"No hay archivos"} />
      )}
      {!loading && !error && files.length > 0 && <FileList files={files} />}
    </Wrapper>
  );
};

export default SharedFiles;
