import { useState } from "react";
import { Box, Typography, Slide } from "@mui/material";
import { styled } from "@mui/system";
import { useLang } from "app/hooks";
import YoiInput from "../../Input";
import { YoiButton } from "../../misc";
import ProfileForm from "./ProfileForm";
import PasswordChange from "./PasswordChange";
const GradientBox = styled(Box)({
  color: "white",
  minWidth: "200px",
  position: "absolute",
  zIndex: -1,
  right: 5,
  top: "0px",
  borderRadius: "30px",
  minHeight: "55px",
  padding: "10px",
  filter: "drop-shadow(10px 10px 14px rgba(0, 0, 0, 0.19));",
  background:
    "linear-gradient(127.27deg, #F81E38 3.14%, #F81E38 19.22%, #7814E4 67.87%, #7814E4 86.16%)",
});

const GradientForm = styled(GradientBox)({
  position: "absolute",
  top: 60,
  paddingY: 3,
  minWidth: "600px",
  zIndex: 999,
});

const MenuElement = styled(Typography)({
  ":hover": {
    textDecoration: "underline",
  },
});

const Overlay = styled(Box)({
  width: "130vw",
  height: "100vh",
  position: "absolute",
  zIndex: 90,
  top: -60,
  right: -30,
});

const menuItemStyles = {};

const Menu = ({ isOpen, handleClose, logout }) => {
  const [menuState, setMenuState] = useState("base");
  const { content } = useLang();
  const handleSetBase = () => setMenuState("base");
  const handleCloseFullMenu = () => {
    handleSetBase();
    handleClose();
  };
  const handleSetMenuState = (state) => setMenuState(state);
  const menuContent = content?.dropdown.base;
  return (
    <>
      {isOpen && (
        <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
          <GradientBox>
            {menuState === "base" && (
              <Box
                sx={{
                  marginTop: "55px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  gap: 1,
                  justifyContent: "end",
                }}
              >
                <MenuElement onClick={() => handleSetMenuState("profile")}>
                  {menuContent.profile}
                </MenuElement>
                <MenuElement onClick={() => handleSetMenuState("password")}>
                  {menuContent.passChange}
                </MenuElement>
                <MenuElement onClick={logout}>{menuContent.logout}</MenuElement>
              </Box>
            )}
          </GradientBox>
        </Slide>
      )}
      {menuState === "profile" && (
        <>
          <Overlay onClick={handleSetBase} />
          <Slide
            direction="left"
            in={menuState === "profile"}
            mountOnEnter
            unmountOnExit
          >
            <GradientForm>
              <ProfileForm handleClose={handleCloseFullMenu} />
            </GradientForm>
          </Slide>
        </>
      )}
      {menuState === "password" && (
        <>
          <Box position="relative">
            <Overlay onClick={handleSetBase} />
          </Box>
          <Slide
            direction="left"
            in={menuState === "password"}
            mountOnEnter
            unmountOnExit
          >
            <GradientForm>
              <PasswordChange
                handleClose={handleCloseFullMenu}
                active={menuState === "password"}
              />
            </GradientForm>
          </Slide>
        </>
      )}
    </>
  );
};

export default Menu;
