import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import {
  SUGGESTION_CASES,
  DAILY_THEME_CASES,
  SUPPORT_GROUP_CASES,
} from "app/consts/socketCases";

const basePageEndpoint = "/cms/api/v2/pages/?";
const baseSupportEndpoint = "/referrers/api/support_groups/?limit=1";
const options = "&limit=1&fields=*";

const suggestionBaseEndpoints = `${basePageEndpoint}type=cms.SuggestionPage${options}`;
const themeBaseEndpoint = `${basePageEndpoint}type=cms.ThemeOfTheDayPage${options}`;

const suggestionEndpoints = [
  `${suggestionBaseEndpoints}&order=-share_count`,
  `${suggestionBaseEndpoints}&order=-like_count`,
  `${suggestionBaseEndpoints}&order=-chat_message_count`,
];
const themeEndpoints = [
  `${themeBaseEndpoint}&order=-rating_avg,-rating_count`,
  `${themeBaseEndpoint}&order=-share_count`,
  `${themeBaseEndpoint}&order=-chat_message_count`,
];
const supportEndpoints = [
  `${baseSupportEndpoint}&order=-users_count`,
  `${baseSupportEndpoint}&order=users_count`,
  `${baseSupportEndpoint}&order=-total_reaction`,
];

export const getSuggestions = createAsyncThunk(
  "metricDetails/getSuggestions",
  async () => {
    const responses = suggestionEndpoints.map((endpoint) =>
      axios.get(endpoint)
    );
    const [sharedResponse, likedResponse, chatResponse] = await Promise.all(
      responses
    );
    return {
      sharedResponse: sharedResponse.data.items[0],
      likedResponse: likedResponse.data.items[0],
      chatResponse: chatResponse.data.items[0],
    };
  }
);

export const getThemeOfTheDayData = createAsyncThunk(
  "metricDetails/getThemeOfTheDayData",
  async () => {
    const responses = themeEndpoints.map((endpoint) => axios.get(endpoint));
    const [likedResponse, sharedResponse, commentedResponse] =
      await Promise.all(responses);
    return {
      likedResponse: likedResponse.data.items[0],
      sharedResponse: sharedResponse.data.items[0],
      chatResponse: commentedResponse.data.items[0],
    };
  }
);

export const getSupportGroupData = createAsyncThunk(
  "metricDetails/getSupportGroupData",
  async (username) => {
    const responses = supportEndpoints.map((endpoint) =>
      axios.get(`${endpoint}&username=${username}`)
    );
    const [mostUsers, lessUsers, mostReactions] = await Promise.all(responses);
    return {
      mostUsers: mostUsers.data.results?.[0],
      lessUsers: lessUsers.data.results?.[0],
      mostReactions: mostReactions.data.results?.[0],
    };
  }
);

const metricsSlice = createSlice({
  name: "metricDetails",
  initialState: {
    suggestions: {
      fulfilled: false,
      loading: false,
      mostLiked: {},
      mostUsers: {},
      mostShared: {},
    },
    dailyTheme: {
      fulfilled: false,
      loading: false,
      mostLiked: {},
      mostUsers: {},
      mostShared: {},
    },
    supportGroup: {
      fulfilled: false,
      loading: false,
      mostUsers: {},
      lessUsers: {},
      mostReactions: {},
      mostCommented: {},
    },
    fetchStatus: {
      error: {
        status: false,
        message: "",
      },
      loading: false,
    },
  },
  reducers: {
    processSuggestionSocket: (state, action) => {
      const { payload } = action;
      const { LIKED_SUGGESTION, COMMENTED_SUGGESTION, SHARED_SUGGESTION } =
        SUGGESTION_CASES;
      if (payload.ref === LIKED_SUGGESTION)
        state.suggestions.mostLiked = {
          ...state.suggestions.mostLiked,
          ...payload,
        };

      if (payload.ref === COMMENTED_SUGGESTION)
        state.suggestions.mostUsers = {
          ...state.suggestions.mostUsers,
          ...payload,
        };

      if (payload.ref === SHARED_SUGGESTION)
        state.suggestions.mostShared = {
          ...state.suggestions.mostShared,
          ...payload,
        };
    },
    processDailySocket: (state, action) => {
      const { payload } = action;
      const { RATED_DAILY, COMMENTED_DAILY, SHARED_DAILY } = DAILY_THEME_CASES;

      if (payload.ref === RATED_DAILY)
        state.dailyTheme.mostLiked = {
          ...state.dailyTheme.mostLiked,
          ...payload,
        };
      if (payload.ref === COMMENTED_DAILY)
        state.dailyTheme.mostUsers = {
          ...state.dailyTheme.mostUsers,
          ...payload,
        };
      if (payload.ref === SHARED_DAILY)
        state.dailyTheme.mostShared = {
          ...state.dailyTheme.mostShared,
          ...payload,
        };
    },
    processSupportSocket: (state, action) => {
      const { payload } = action;
      const { MOST_USERS, LESS_USERS, MOST_LIKED } = SUPPORT_GROUP_CASES;

      if (payload.ref === MOST_USERS)
        state.supportGroup.mostUsers = {
          ...state.supportGroup.mostUsers,
          ...payload,
          users_count: payload.total_user,
        };

      if (payload.ref === LESS_USERS)
        state.supportGroup.lessUsers = {
          ...state.supportGroup.lessUsers,
          ...payload,
          users_count: payload.total_user,
        };

      if (payload.ref === MOST_LIKED)
        state.supportGroup.mostReactions = {
          ...state.supportGroup.mostReactions,
          ...payload,
        };
    },
  },
  extraReducers: (builder) => {
    // ! Suggestion Data Reducers
    builder.addCase(getSuggestions.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.suggestions.loading = true;
      state.suggestions.fulfilled = false;
    });
    builder.addCase(getSuggestions.rejected, (state, action) => {
      state.fetchStatus.error.status = true;
      state.suggestions.loading = false;
      state.suggestions.fulfilled = false;
    });
    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions.mostLiked = action.payload.likedResponse;
      state.suggestions.mostShared = action.payload.sharedResponse;
      state.suggestions.mostUsers = action.payload.chatResponse;
      state.suggestions.loading = false;
      state.suggestions.fulfilled = true;
    });
    // ! End Suggestion Data Reducers
    // ! dailyTheme data reducers
    builder.addCase(getThemeOfTheDayData.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.dailyTheme.loading = true;
      state.dailyTheme.fulfilled = false;
    });
    builder.addCase(getThemeOfTheDayData.rejected, (state, action) => {
      state.dailyTheme.loading = false;
      state.fetchStatus.error.status = true;
      state.dailyTheme.fulfilled = false;
    });
    builder.addCase(getThemeOfTheDayData.fulfilled, (state, action) => {
      state.dailyTheme.mostLiked = action.payload.likedResponse;
      state.dailyTheme.mostShared = action.payload.sharedResponse;
      state.dailyTheme.mostUsers = action.payload.chatResponse;
      state.dailyTheme.loading = false;
      state.dailyTheme.fulfilled = true;
    });
    // ! End dailyTheme data reducers
    // ! supportGroup data reducers
    builder.addCase(getSupportGroupData.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.supportGroup.loading = true;
      state.supportGroup.fulfilled = false;
    });
    builder.addCase(getSupportGroupData.rejected, (state, action) => {
      state.supportGroup.loading = false;
      state.fetchStatus.error.status = true;
      state.supportGroup.fulfilled = false;
    });
    builder.addCase(getSupportGroupData.fulfilled, (state, action) => {
      if (action.payload.mostUsers !== undefined)
        state.supportGroup.mostUsers = action.payload.mostUsers;

      if (action.payload.lessUsers !== undefined)
        state.supportGroup.lessUsers = action.payload.lessUsers;

      if (action.payload.mostReactions !== undefined)
        state.supportGroup.mostReactions = action.payload.mostReactions;
      if (action.payload.mostCommented !== undefined) {
        state.supportGroup.mostCommented = action.payload.mostCommented;
      }
      state.supportGroup.loading = false;
      state.supportGroup.fulfilled = true;
    });
    // ! End supportGroup data reducers
  },
});

export const {
  processSuggestionSocket,
  processDailySocket,
  processSupportSocket,
} = metricsSlice.actions;

export default metricsSlice.reducer;
