import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThemeOfTheDayData } from "app/redux/states/metrics/metrics";
import { DAILY_THEME_CASES } from "app/consts/socketCases";
import { useSocketData } from "app/hooks";
import { processDailySocket } from "app/redux/states/metrics/metrics";

const { RATED_DAILY, COMMENTED_DAILY, SHARED_DAILY } = DAILY_THEME_CASES;

const useDailyTheme = () => {
	const dispatch = useDispatch();
	const dailyMetrics = useSelector((state) => state.metricsDetail.dailyTheme);
	const queryParams = {
		refs: `${RATED_DAILY},${COMMENTED_DAILY},${SHARED_DAILY}`,
	};
	const { lastJsonMessage, readyState } = useSocketData("/ws/metrics/", {
		queryParams,
	});
	const fetchDailyData = () => {
		dispatch(getThemeOfTheDayData());
	};

	useEffect(() => {
		if (dailyMetrics.fulfilled) return;
		fetchDailyData();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		dispatch(processDailySocket(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	return {
		fetchDailyData,
		dailyMetrics,
	};
};

export default useDailyTheme;
