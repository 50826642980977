import { useDispatch, useSelector } from "react-redux";
import {
  fetchSuggestions,
  postSuggestion as post,
} from "app/redux/states/dashboard/suggestions";
import { useEffect } from "react";
const useSuggestions = () => {
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions.suggestions);
  const fetchStatus = useSelector((state) => state.suggestions.fetchStatus);
  const getSuggestions = async () => {
    await dispatch(fetchSuggestions());
  };
  const postSuggestion = async (id) => {
    await dispatch(post(id));
  };

  useEffect(() => {
    if (fetchStatus.fulfilled) return;
    getSuggestions();
  }, []);
  return { getSuggestions, suggestions, postSuggestion, fetchStatus };
};

export default useSuggestions;
