import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { routeNames } from "routes/routeIndex.js";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(
  lazy(() => import("./passwordRecovery/StepOne"))
);
const SetPassword = Loadable(lazy(() => import("./passwordRecovery/StepTwo")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));

const sessionRoutes = [
  {
    path: routeNames.auth.login,
    element: <JwtLogin />,
  },
  {
    path: routeNames.auth.passwordRecovery1,
    element: <ForgotPassword />,
  },
  {
    path: routeNames.auth.passwordRecovery2,
    element: <SetPassword />,
  },
  {
    path: routeNames.auth.sessions404,
    element: <NotFound />,
  },
];

export default sessionRoutes;
