import { Card, Box } from "@mui/material";
import MatxSidenav from "app/components/MatxSidenav/MatxSidenav";
import MatxSidenavContainer from "app/components/MatxSidenav/MatxSidenavContainer";
import MatxSidenavContent from "app/components/MatxSidenav/MatxSidenavContent";
import { useState } from "react";
import ChatContainer from "./ChatContainer";
import ChatSidenav from "./ChatSidenav";
import { useAuth } from "app/hooks";
import axios from "axios.js";
import { useToast, useLang } from "app/hooks";
import { useContactChat } from "app/hooks";

const AppChat = ({
  userList = [],
  conversation = {},
  getChatContent,
  userData,
}) => {
  const { handleMessage, currentChat, changeChat } = useContactChat();
  const [open, setOpen] = useState(true);
  const { user } = useAuth();
  const { createToast } = useToast();
  const { content } = useLang();
  const chatToastContent = content?.chatContent.toast;
  const handleMessageSend = async (message, replyId) => {
    handleMessage(message, userData, currentChat);
  };

  const handleContactClick = (contact) => {
    changeChat(contact);
  };

  const toggleSidenav = () => setOpen(!open);
  return (
    <Box sx={{ height: "100%" }}>
      <Card
        elevation={10}
        sx={{
          height: "100%",
          boxShadow: "10px 10px 14px rgba(0, 0, 0, 0.19)",
          borderRadius: "10px",
        }}
      >
        <MatxSidenavContainer
          sx={{
            height: "100%",
          }}
        >
          <MatxSidenav width="200px" open={open} toggleSidenav={toggleSidenav}>
            <ChatSidenav
              contactList={userList}
              currentUser={user}
              currentChat={currentChat}
              handleContactClick={handleContactClick}
            />
          </MatxSidenav>
          <MatxSidenavContent sx={{ height: "100%" }}>
            <ChatContainer
              userData={userData}
              title={currentChat?.username}
              currentChat={currentChat}
              getChatContent={getChatContent}
              handleMessageSend={handleMessageSend}
            />
          </MatxSidenavContent>
        </MatxSidenavContainer>
      </Card>
    </Box>
  );
};

export default AppChat;
