import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFiles as thunkFiles,
  fetchFiles,
  setFiles,
} from "app/redux/states/files/files";
const useFiles = () => {
  const [currentFiles, setCurrentFiles] = React.useState();
  const dispatch = useDispatch();
  const filesStore = useSelector((state) => state.files);
  const { currentChat } = useSelector((state) => state.contactChat);
  const { files, loading, error, page } = filesStore;
  const getFiles = (page, username) => {
    dispatch(thunkFiles({ page, username }));
  };

  const getNextPage = async (page, username) => {
    try {
      let response = await fetchFiles(page, username);
      dispatch(setFiles(response.data));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!currentChat) return;
    if (currentChat.username === currentFiles) return;
    getFiles(page, currentChat.username);
    setCurrentFiles(currentChat.username);
  }, [currentChat]);
  return { files, loading, error, page, getFiles, getNextPage, currentChat };
};

export default useFiles;
