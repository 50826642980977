import React from "react";
import { Box } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import Card from "./Card";

const ContactCards = () => {
  return (
    <Box
      display="flex"
      sx={{
        flexDirection: { xs: "column", xl: "row" },
        height: "100%",
        justifyContent: { xs: "space-around", xl: "space-between" },
        alignItems: { md: "normal", xl: "center" },
      }}
    >
      <Card title="Citas agendadas" subtitle={"Ultimos 7 días"} count="23">
        <CalendarMonth />
      </Card>
      <Card title="Citas agendadas" subtitle={"Ultimos 7 días"} count="23">
        <CalendarMonth />
      </Card>
      <Card title="Citas agendadas" subtitle={"Ultimos 7 días"} count="23">
        <CalendarMonth />
      </Card>
    </Box>
  );
};

export default ContactCards;
