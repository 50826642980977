// create a new slice of state for files

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
// create a thunk to fetch files from the server

const endpoint = "/referrers/api/document/shared/";

export const fetchFiles = (page, username) => {
  return axios.get(`${endpoint}${username}/`);
};

export const getFiles = createAsyncThunk(
  "files/getFiles",
  async ({ page, username }, { rejectWithValue }) => {
    const response = await fetchFiles(page, username);
    return { data: response.data, username };
  }
);

const initialState = {
  files: [],
  selectedFile: null,
  loading: false,
  error: false,
  page: 1,
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      // Update the files state with the previous files and the new files, check for duplicates
      const newFiles = action.payload;
      const oldFiles = state.files;
      const files = [...oldFiles, ...newFiles];
      const uniqueFiles = files.filter(
        (file, index, self) => index === self.findIndex((t) => t.id === file.id)
      );
      state.files = uniqueFiles;

      state.page = state.page + 1;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFiles.pending, (state) => {
      state.page = 1;
      state.loading = true;
    });
    builder.addCase(getFiles.fulfilled, (state, action) => {
      state.loading = false;
      const files = action.payload.data;
      // divide files by date using last_modified as date in an array of objects that have the property date and files
      const filesByDate = [];
      files.forEach((file) => {
        const date = file.last_modified.split("T")[0];
        const fileByDate = filesByDate.find((file) => file.date === date);
        if (fileByDate) {
          fileByDate.files.push(file);
        } else {
          filesByDate.push({ date, files: [file] });
        }
      });

      console.log(filesByDate);
      state.files = filesByDate;
      state.page = state.page + 1;
    });
    builder.addCase(getFiles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setFiles, setError } = filesSlice.actions;

export default filesSlice.reducer;
