import Card from "../Card";
import { Grid, Avatar, Box, Typography, styled } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ShareIcon from "@mui/icons-material/Share";
import ForwardButton from "../ForwardButton";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes/routeIndex";

const ProfileImg = styled(Avatar)({
  width: "60px",
  height: "60px",
  borderRadius: "100%",
  border: "1px solid #7814E4",
});

const ForumImg = styled("img")({
  width: "120px",
  height: "120px",
  borderRadius: "10px",
});

const SupportCard = ({
  type = routeNames.yoiDashboard.supportGroups,
  conversation,
  created_at: createdAt,
  name,
  owner_user: ownerUser,
  users_count: usersCount,
  share_count: shareCount,
  category_preference: categoryPreference,
  img,
  category,
}) => {
  const navigate = useNavigate();

  const created = new Date(createdAt);

  const yyyy = created.getFullYear();
  let mm = created.getMonth() + 1; // Months start at 0!
  let dd = created.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  return (
    <Card
      extraStyles={{
        height: "100%",
        paddingY: 1,
        paddingX: 3,
      }}
    >
      <Grid container height="100%">
        <Grid
          item
          container
          flexDirection="column"
          sm={3}
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
        >
          {img ? <ForumImg src={img} /> : <ProfileImg src={ownerUser.photo} />}
          <Typography fontWeight={700} fontSize={16} color="#6A6A6A">
            {ownerUser.username}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm={6}
          alignItems="center"
          justifyContent="end"
          px={3}
          py={3.5}
          position="relative"
          overflow="hidden"
        >
          <Typography
            color="#6A6A6A"
            fontSize={45}
            fontWeight={700}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {name}
          </Typography>
          <Box position="absolute" bottom={0} display="flex" gap={1.5}>
            <Typography fontSize={"8px"}>{category?.name}</Typography>
            <Typography fontSize={"8px"}>{formattedToday}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sm={2}
          borderLeft="2px solid purple"
          pl={1}
        >
          <Box display="flex" alignItems="center">
            <PeopleIcon fontSize="large" />
            <Typography fontSize={36} fontWeight={700} color="#6A6A6A">
              {usersCount || 0}
            </Typography>
          </Box>
          {/* <Box display="flex" alignItems="center">
            <ShareIcon fontSize="large" />
            <Typography fontSize={36} fontWeight={700} color="#6A6A6A">
              0
            </Typography>
          </Box> */}
        </Grid>
        <Grid item container xs={1} justifyContent="center" alignItems="center">
          <ForwardButton click={() => navigate(`${type}/${conversation}`)} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default SupportCard;
