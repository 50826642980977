import { useDispatch, useSelector } from "react-redux";
import {
	getSuggestionList,
	addData,
	addSearchData,
} from "app/redux/states/suggestions/suggestionDetails";

import axios from "axios.js";

const useSuggestionDetails = () => {
	const dispatch = useDispatch();
	const offset = useSelector((state) => state.suggestionDetails.offset);
	const totalItems = useSelector((state) => state.suggestionDetails.total);
	const suggestionList = useSelector((state) => state.suggestionDetails.data);
	const status = useSelector((state) => state.suggestionDetails.fetchStatus);
	const getSuggestions = (queryParams = {}) =>
		dispatch(getSuggestionList({ queryParams }));

	const suggestionSearch = async (queryParams) => {
		const params = {
			type: "cms.SuggestionPage",
			fields: "*",
			...queryParams,
		};
		try {
			const response = await axios.get(`/cms/api/v2/pages/`, { params });
			dispatch(addSearchData({ items: response.data.items }));
		} catch (error) {
			console.log(error);
		}
	};
	const getNextSuggestions = async (offset) => {
		const params = {
			type: "cms.SuggestionPage",
			fields: "*",
			limit: 10,
			offset: offset,
		};
		const response = await axios.get(`/cms/api/v2/pages/`, { params });
		if (!response.statusText === "OK" || !response.status == 200 || !response.status == 201) return;
		dispatch(
			addData({
				data: response.data.items,
				offset: offset + response.data.items.length,
			})
		);
	};
	return {
		suggestionList,
		status,
		totalItems,
		offset,
		getSuggestions,
		getNextSuggestions,
		suggestionSearch,
	};
};

export default useSuggestionDetails;
