import { styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";

import { Box } from "@mui/material";

export const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
}));
export const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));

export const YoiLoginBg = styled(JustifyBox)({
  background:
    "linear-gradient(127.27deg, #F81E38 3.14%, #F81E38 19.22%, #7814E4 67.87%, #7814E4 86.16%);",
  minHeight: "100% !important",
  "& .card": {
    display: "flex",
    alignItems: "center",
    maxWidth: 800,
    minHeight: 400,
    borderRadius: 12,
    margin: "1rem",
  },
});

export const YoiButton = styled(LoadingButton)({
  height: "60px",
  width: "100%",
  color: "white",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 700,
  backgroundColor: "#F81E38",
  "&:hover": {
    backgroundColor: "#F81E38",
    filter: "brightness(97%)",
  },
  "&:hover loading": {
    backgroundColor: "white",
  },
});
