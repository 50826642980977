import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupportGroupData } from "app/redux/states/metrics/metrics";
import { useSocketData, useAuth } from "app/hooks";
import { SUPPORT_GROUP_CASES } from "app/consts/socketCases";
import { processSupportSocket } from "app/redux/states/metrics/metrics";

const { LESS_USERS, MOST_USERS, MOST_LIKED } = SUPPORT_GROUP_CASES;

const useSupportGroup = () => {
	const { user } = useAuth();
	const dispatch = useDispatch();
	const queryParams = {
		refs: `${LESS_USERS},${MOST_USERS},${MOST_LIKED}`,
		referrer: user.username,
	};
	const { lastJsonMessage, readyState } = useSocketData("/ws/metrics/", {
		queryParams,
	});
	const supportGroup = useSelector((state) => state.metricsDetail.supportGroup);
	const fetchSupportGroup = () => {
		dispatch(getSupportGroupData(user.username));
	};

	useEffect(() => {
		if (supportGroup.fulfilled) return;
		fetchSupportGroup();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;

		dispatch(processSupportSocket(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	return {
		fetchSupportGroup,
		supportGroup,
	};
};

export default useSupportGroup;
