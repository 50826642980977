import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDailyWisdom } from "app/redux/states/dashboard/wisdom";
import { useEffect } from "react";
const useWisdom = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.wisdom.fetchStatus);
  const wisdom = useSelector((state) => state.wisdom.dailyWisdom);
  const getWisdom = () => {
    dispatch(fetchDailyWisdom());
  };
  useEffect(() => {
    if (fetchStatus.fulfilled) return;
    getWisdom();
  }, []);
  return {
    getWisdom,
    wisdom,
    fetchStatus,
  };
};

export default useWisdom;
