import { Snackbar, Alert } from "@mui/material";

// Imported from YOI Ecosystem
const getErrorMessage = (error) => {
  if (!error.response && error.error) {
    error = error.error;
  }
  if (!error.response) return "Error en la petición";

  const errorDetails = error.response.data;
  const key = Object.keys(errorDetails)[0];
  if (Array.isArray(errorDetails[key])) return errorDetails[key][0];
  return errorDetails[key];
};

const toastConfigs = {
  autoHideDuration: 2000,
  anchorOrigin: { vertical: "top", horizontal: "center" },
};

const severities = ["error", "warning", "info", "success"];

const ToastMsg = ({ message }) => {
  return <span id="message-id">{message}</span>;
};

const Toast = ({ isOpen, handleClose, status, message }) => {
  return !status ? (
    <Snackbar
      open={isOpen}
      onClose={() => handleClose()}
      key={`${message}-toast-key`}
      message={<ToastMsg message={message} />}
      {...toastConfigs}
    />
  ) : severities.includes(status) && status === "error" ? (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      key={`${getErrorMessage(message).trim()}-toast-key`}
      {...toastConfigs}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={status}
        sx={{ width: "100%" }}
      >
        {getErrorMessage(message)}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      key={`${message}-toast-key`}
      {...toastConfigs}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={status}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
