import { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth";
import { useSelector, useDispatch } from "react-redux";
import {
  setContacts,
  setContactMessages,
  setCurrentChat,
} from "app/redux/states/chat/contactChatSlice";

const baseUrl = "https://tc.yoifirst.com/QuickChat/";
const useQckChat = () => {
  const { user } = useAuth();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("accessToken")
  );
  const contacts = useSelector((state) => state.contactChat.contacts);
  const [userData, setData] = useState(null);
  const [contactList, setContactList] = useState(null);
  const dispatch = useDispatch();

  const handleContacts = (contacts) => {
    dispatch(setContacts(contacts));
  };
  const getMockUser = async () => {
    try {
      const response = await axios.post(`${baseUrl + "AutoLogIn"}`, {
        type: "JSON",
      });
      setData({ ...response.data.payload, uuid: "SU00000001" });
    } catch (error) {
      console.log(error);
    }
  };
  const getMockContactList = async () => {
    try {
      let sqToquen = authToken;
      let data = {
        sqToquen,
        registryUID: user.UUID.toUpperCase(),
      };

      const response = await axios.post(
        `${baseUrl + "GetEnrichedContacts"}`,
        data,
        {
          type: "JSON",
        }
      );
      const userlist = response.data.payload.map((user) => {
        // from user update userName to username, userID to id and change date format from unix timestamp to dd/mm/yyyy
        return {
          ...user,
          username: user.userName,
          id: user.userID,
          lastMsg: {
            content: user.message,
            sent: user.elapsed,
          },
          isOnline: user.isOnline === "true",
          messages: [],
          date: new Date(parseInt(user.time)).toLocaleDateString(),
        };
      });
      handleContacts(userlist);
    } catch (error) {
      console.log(error);
    }
  };

  const getChatContent = async (contact) => {
    let body =
      user.role === "agente"
        ? {
            registryUID: user.UUID.toUpperCase(),
            receiverUID: contact.id.toUpperCase(),
          }
        : {
            registryUID: contact.id.toUpperCase(),
            receiverUID: user.UUID.toUpperCase(),
          };
    try {
      const response = await axios.post(`${baseUrl + "GetChatContent"}`, body, {
        type: "JSON",
      });
      dispatch(
        setContactMessages({ messages: response.data.payload, id: contact.id })
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userData) {
      getMockUser();
    }
    if (!contactList) {
      getMockContactList();
    }
  }, [userData, contactList]);

  return { userData, contactList, getChatContent, contacts, handleContacts };
};

export default useQckChat;
