import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios.js";
let endpoint =
  "/cms/api/v2/pages/?type=cms.DailyWisdomPage&limit=4&order=-share_count&fields=*";

export const fetchDailyWisdom = createAsyncThunk(
  "wisdom/fetchWisdom",
  async () => {
    const response = await axios.get(endpoint);
    return response.data.items;
  }
);
const wisdomSlice = createSlice({
  name: "wisdom",
  initialState: {
    dailyWisdom: [],
    fetchStatus: {
      loading: false,
      fulfilled: false,
      error: {
        status: false,
        message: "",
      },
    },
  },
  reducers: {
    changeSharedCount: (state, action) => {
      const { payload } = action;
      const index = state.dailyWisdom.findIndex(
        (wisdom) => wisdom.id === payload.object_pk
      );
      state.dailyWisdom[index] = {
        ...state.dailyWisdom[index],
        share_count: state.dailyWisdom[index].share_count + payload.count,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDailyWisdom.pending, (state, action) => {
      state.fetchStatus.fulfilled = false;
      state.fetchStatus.error.status = false;
      state.fetchStatus.loading = true;
    });
    builder.addCase(fetchDailyWisdom.fulfilled, (state, action) => {
      state.fetchStatus.error.status = false;
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = true;
      state.dailyWisdom = action.payload;
    });
    builder.addCase(fetchDailyWisdom.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.error.status = true;
    });
  },
});

export const { changeSharedCount } = wisdomSlice.actions;

export default wisdomSlice.reducer;
