const ws = "wss://qc.yoifirst.com:443/";

const calcUserCode = (userType, userID) => {
  var hexUserID = userID.toString(16);

  while (hexUserID.length < 8) {
    hexUserID = "0" + hexUserID;
  }

  return "S" + userType + hexUserID;
};
// create function that returns a connection message that has the user's id and default type of "CONNECT", and a token
const createConnectionMsg = (userId, token) => {
  return {
    type: "CONNECT",
    registryUID: userId,
    token: token,
  };
};

// declare a wsCase object that has the type of the message and the handler function that will be called when the message is received
const wsCases = {
  connect: {
    type: "hello",
    handler: (e) => {
      console.log("Hello", e);
    },
  },
  ping: {
    type: "TIME",
    handler: (e) => {
      console.log("TIME", e);
    },
  },
  online: {
    type: "ONLINE",
    handler: (e) => {
      console.log("ONLINE", e);
    },
  },
  discussion: {
    type: "DISCUSSION:",
    handler: (e) => {
      console.log("Receive Contact", e);
    },
  },
  message: {
    type: "OBJECT:",
    handler: (e) => {
      console.log("Receive Object", e);
    },
  },
  msg: {
    type: "MESSAGE",
    handler: (e) => {
      console.log("Receive Object", e);
    },
  },
};

// Create a function that sends a text message to the receiver with the user's information
const sendText = (textMsg, receiver, user) => {
  const defaultType = "kSingleUser";
  if (textMsg.length === 0) {
    // Should show a toast
    console.log("Empty text.");
    return;
  }
  const msg = {
    type: "TEXT_MSG",
    senderName: user.name,
    senderID: user.id,
    senderType: defaultType,
    receiverName: receiver.name,
    receiverID: receiver.id,
    receiverType: defaultType,
    textMessage: textMsg,
  };
  return JSON.stringify(msg);
};

// Create a function that handles the message received from the server using a switch statement
const wsHandleEvent = (wsMsg) => {
  switch (wsMsg.type) {
    case wsCases.connect.type:
      console.log("connected");
      wsCases.connect.handler(wsMsg);
      break;
    case wsCases.ping.type:
      console.log("ping");
      wsCases.ping.handler(wsMsg);
      break;
    case wsCases.online.type:
      console.log("online");
      wsCases.online.handler(wsMsg);
      break;
    case wsCases.discussion.type:
      console.log("discussion");
      wsCases.discussion.handler(wsMsg);
      break;
    case wsCases.message.type:
      console.log("message");
      wsCases.message.handler(wsMsg);
      break;
    default:
      console.log(wsMsg);
      break;
  }
};

export {
  ws,
  wsCases,
  sendText,
  createConnectionMsg,
  wsHandleEvent,
  calcUserCode,
};
