import React from "react";
import { Toast } from "app/components/yoi";

import { useSelector, useDispatch } from "react-redux";
import { handleClose } from "app/redux/states/notification";
const ToastProvider = () => {
	const { isOpen, message, status } = useSelector(
		(state) => state.notifications
	);
	const dispatch = useDispatch();
	return (
		<Toast
			handleClose={() => dispatch(handleClose())}
			isOpen={isOpen}
			message={message}
			status={status}
		/>
	);
};

export default ToastProvider;
