import React from "react";
import GradientBox from "../GradientBox";
import { Box, Typography } from "@mui/material";
import { YoiButton } from "app/components/yoi";
import { getEvents, getAccounts } from "app/nylas/requests";
import Day from "./Day";
const Calendly = () => {
  const [dates, setDates] = React.useState(null);
  const fetchEvents = async () => {
    const events = await getEvents();
    setDates(events);
  };
  React.useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent={"space-between"}
    >
      <GradientBox title="citas">
        {dates &&
          dates.map((day) => (
            <React.Fragment key={JSON.stringify(day)}>
              <Day date={day.date} appointments={day.events} />
            </React.Fragment>
          ))}
      </GradientBox>
      <Box mt={2} textAlign="center">
        <Typography>
          ¿Quieres reagendar o cancelar tu cita?
          <Typography variant="span" fontWeight={700}>
            Haz click acá
          </Typography>
        </Typography>
        <YoiButton>Citas</YoiButton>
      </Box>
    </Box>
  );
};

export default Calendly;
