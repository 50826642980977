import { useState } from "react";

const useToggle = (defaultValue = false) => {
	const [open, setOpen] = useState(defaultValue);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleToggle = () => setOpen((current) => !current);

	return {
		open,
		handleOpen,
		handleClose,
		handleToggle,
	};
};

export default useToggle;
