import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
let endpoint =
  "/cms/api/v2/pages/?type=cms.ThemeOfTheDayPage&limit=1&order=-created_at&fields=*";

export const fetchDailyTheme = createAsyncThunk(
  "dailyTheme/fetchDailyTheme",
  async () => {
    const response = await axios.get(endpoint);
    return response.data.items;
  }
);
const dailySlice = createSlice({
  name: "dailyTheme",
  initialState: {
    dailyTheme: {},
    fetchStatus: {
      loading: false,
      fulfilled: false,
      error: {
        status: false,
        message: "",
      },
    },
  },
  reducers: {
    changeSharedCount: (state, action) => {
      const { payload } = action;
      state.dailyTheme = {
        ...state.dailyTheme,
        share_count: state.dailyTheme.share_count + payload.count,
      };
    },
    changeUserCount: (state, action) => {
      const { payload } = action;
      state.dailyTheme = {
        ...state.dailyTheme,
        chat_users_count: state.dailyTheme.chat_users_count + payload.count,
      };
    },
    changeForumCount: (state, action) => {
      const { payload } = action;
      state.dailyTheme = {
        ...state.dailyTheme,
        chat_count: state.dailyTheme.chat_count + payload.count,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDailyTheme.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.fetchStatus.fulfilled = false;
      state.fetchStatus.loading = true;
    });
    builder.addCase(fetchDailyTheme.fulfilled, (state, action) => {
      
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = true;
      state.dailyTheme = action.payload[0];
    });
    builder.addCase(fetchDailyTheme.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.error.status = true;
    });
  },
});

export const { changeSharedCount, changeUserCount, changeForumCount } =
  dailySlice.actions;

export default dailySlice.reducer;
