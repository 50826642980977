import { styled } from "@mui/material";

export const Scrollbar = styled("div")({
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "5px",
    height: "30px",
    marginLeft: "30px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#999999",
    borderRadius: "8px",
    width: "2px",
  },
});
