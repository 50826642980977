import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { SOCKET_CASES } from "app/consts/socketCases";

let endpoint = "/referrers/api/total_user/filter/?username";

export const fetchYoiGrow = createAsyncThunk(
	"growth/fetchYoiGrow",
	async (username) => {
		const response = await axios.get(`${endpoint}=${username}`);
		return response.data;
	}
);

export const processSocketGrowth = createAsyncThunk(
	"growth/processSocketGrowth",
	async (growth) => {
		return growth;
	}
);

const dailySlice = createSlice({
	name: "growth",
	initialState: {
		growth: {
			yoi: 0,
			user: 0,
		},
		fetchStatus: {
			loading: false,
			fulfilled: false,
			error: {
				status: false,
				message: "",
			},
		},
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchYoiGrow.pending, (state, action) => {
			state.fetchStatus.fulfilled = false;
			state.fetchStatus.error.status = false;
			state.fetchStatus.loading = true;
		});
		builder.addCase(fetchYoiGrow.fulfilled, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.fulfilled = true;

			state.growth.yoi = action.payload.user_yoi;
			state.growth.user = action.payload.user_referrer;
		});
		builder.addCase(fetchYoiGrow.rejected, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.error.status = true;
		});
		builder.addCase(processSocketGrowth.fulfilled, (state, action) => {
			if (action.payload.ref === SOCKET_CASES.YOI_GROWTH)
				state.growth.yoi += action.payload.count;
			if (action.payload.ref === SOCKET_CASES.USER_GROWTH)
				state.growth.user += action.payload.count;
		});
	},
});

export default dailySlice.reducer;
