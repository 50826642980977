import {
  Box,
  CircularProgress,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import ChatAvatar from "app/components/ChatAvatar";
import { Paragraph, Small, Span } from "app/components/Typography";
import { getTimeDifference } from "app/utils/utils";
import shortid from "shortid";
import { useAuth } from "app/hooks";
const UserStatus = styled("div")(({ isOwner }) => ({
  padding: "8px 16px",
  marginBottom: "8px",
  borderRadius: "5px",
  color: isOwner && "#fff",
  background: isOwner ? "#2E2E2E" : "#C7C7C7",
}));

const Message = styled("div")(({ isOwner }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: isOwner ? "row-reverse" : "row",
  gap: 5,
  padding: "12px 16px",
}));

const MessageBubble = ({ message, userData }) => {
  const { user } = useAuth();
  let userUUID = user.UUID;
  let userIsOwner = userUUID === message.senderID;
  return (
    <Message key={shortid.generate()} isOwner={userIsOwner}>
      <Tooltip title={message.senderName} sx={{ minWidth: "40px" }}>
        {message.posted_by_user?.photo ? (
          <ChatAvatar src={message.posted_by_user.photo} status={true} />
        ) : (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              backgroundColor: "#2E2E2E",
            }}
          >
            {message.senderName[0]}
          </Box>
        )}
      </Tooltip>
      <Box maxWidth="85%">
        <UserStatus isOwner={userIsOwner}>
          <Box maxWidth={"80%"}>
            <Span
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {message.content.text}
            </Span>
          </Box>
        </UserStatus>
        <Box display={"flex"} gap={1}></Box>
      </Box>
    </Message>
  );
};

export default MessageBubble;
