import React from "react";
import { Box, Typography } from "@mui/material";
import YoiInput from "../../Input";
import { Formik } from "formik";
import axios from "axios.js";
import { useToast } from "app/hooks";

import { YoiButton } from "../../misc";
import { useState } from "react";
const inputControlStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 1,
  "& p": {
    width: "80px",
  },
};
const PasswordChange = ({ handleClose }) => {
  const { createToast } = useToast();
  const [loading, setLoading] = useState(false);
  const handlePasswordChange = async (values, resetForm) => {
    setLoading(true);
    if (!values.oldPassword || !values.newPassword || !values.passwordConfirm) {
      createToast("Todos los campos son requeridos", "warning");
      setLoading(false);
      return;
    }
    if (values.newPassword === values.oldPassword) {
      createToast("No puedes utilizar la misma contraseña");
      setLoading(false);
      return;
    }
    if (values.newPassword !== values.passwordConfirm) {
      createToast("Las contraseñas deben ser iguales");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post("/dj-rest-auth/password/change/", {
        old_password: values.oldPassword,
        new_password1: values.newPassword,
        new_password2: values.passwordConfirm,
      });
      if (response.statusText === "OK" || response.status == 200 || response.status == 201) {
        setLoading(false);
        resetForm({ values: "" });
        createToast("Contraseña cambiada con exito!", "success");
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      createToast(error, "error");
    }
  };
  return (
    <Box>
      <Typography fontSize="32px" textAlign={"center"} fontWeight={"700"}>
        Cambia tu contraseña
      </Typography>
      <Formik
        onSubmit={(values, { resetForm }) =>
          handlePasswordChange(values, resetForm)
        }
        initialValues={{
          oldPassword: "",
          newPassword: "",
          passwordConfirm: "",
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 3,
                mt: 8,
                height: "400px",
              }}
            >
              <Box display="flex" flexDirection={"column"} gap={4}>
                <Box sx={inputControlStyles}>
                  <Typography>Contraseña actual</Typography>
                  <YoiInput
                    onChange={handleChange}
                    name="oldPassword"
                    type="password"
                    value={values.oldPassword}
                    sx={{ width: "300px" }}
                  />
                </Box>
                <Box sx={inputControlStyles}>
                  <Typography>Contraseña nueva</Typography>
                  <YoiInput
                    onChange={handleChange}
                    name="newPassword"
                    type="password"
                    value={values.newPassword}
                    sx={{ width: "300px" }}
                  />
                </Box>
                <Box sx={inputControlStyles}>
                  <Typography>Confirmar Contraseña</Typography>
                  <YoiInput
                    onChange={handleChange}
                    name="passwordConfirm"
                    type="password"
                    value={values.passwordConfirm}
                    sx={{ width: "300px" }}
                  />
                </Box>
              </Box>
              <YoiButton
                loading={loading}
                type="submit"
                sx={{ width: "300px" }}
              >
                Aceptar
              </YoiButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PasswordChange;
