import { useDispatch, useSelector } from "react-redux";
import {
  closeWelcomeMessage,
  handleMessage,
  openWelcomeMessage,
} from "app/redux/states/notification";

const useToast = () => {
  const dispatch = useDispatch();
  const welcomeMessage = useSelector(
    (state) => state.notifications.welcomeMessage
  );
  const handleOpenWelcomeMessage = () => {
    dispatch(openWelcomeMessage());
  };
  const handleCloseWelcomeMessage = () => {
    dispatch(closeWelcomeMessage());
  };
  const createToast = (message, status) => {
    dispatch(handleMessage({ message, status }));
  };
  return {
    createToast,
    handleCloseWelcomeMessage,
    handleOpenWelcomeMessage,
    welcomeMessage,
  };
};

export default useToast;
