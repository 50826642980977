import AuthGuard from "app/auth/AuthGuard";
import Redirect from "app/auth/Redirect";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { YoiRoutes } from "./views/yoi-dashboard/YoiRoutes";
import YoiLayout from "app/components/yoi/Layout/YoiLayout";
import { AgentRoutes as ARoutes } from "./views/agent/AgentRoutes";

const genericRoutes = [
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export const UserRoutes = [
  {
    element: (
      <AuthGuard>
        <YoiLayout />
      </AuthGuard>
    ),
    children: [...YoiRoutes],
  },
  ...genericRoutes,
];

export const AgentRoutes = [
  {
    element: (
      <AuthGuard>
        <YoiLayout />
      </AuthGuard>
    ),
    children: [...ARoutes],
  },
  ...genericRoutes,
];
