import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import MessageBubble from "./MessageBubble";
import { useAuth } from "app/hooks";
const MessageListContainer = () => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const contacts = useSelector((state) => state.contactChat.contacts);
  const currentChat = useSelector((state) => state.contactChat.currentChat);
  const bottomRef = useRef(null);
  useEffect(() => {
    if (!currentChat) return;
    setMessages(
      contacts.find((contact) => contact.id === currentChat.id).messages
    );
    if (bottomRef) {
      bottomRef.current.scrollIntoView();
    }
  }, [currentChat, contacts]);

  if (!currentChat) return null;
  return (
    <>
      {messages.length > 0 &&
        currentChat.messages.map((message) => (
          <MessageBubble key={message.id} message={message} userData={user} />
        ))}
      <Box ref={bottomRef} />
    </>
  );
};

export default MessageListContainer;
