import { Button as MuiButton } from "@mui/material";

const baseStyles = {
	backgroundColor: "#F81E38",
	width: "fit-content",
	color: "white",
	border: "2px solid #F81E38",
	"&:hover": {
		backgroundColor: "#9e1626",
	},
};

const Button = ({ children, sx, ...others }) => {
	const styles = {
		...baseStyles,
		...sx,
	};
	return (
		<MuiButton sx={styles} {...others}>
			{children}
		</MuiButton>
	);
};

export default Button;
