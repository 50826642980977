import { Typography, Box } from "@mui/material";
import YoiInput from "../Input";

const SearchInput = ({ children, onInput, type, ...others }) => {
	return (
		<Box display='flex' flexDirection='column' gap={1}>
			<Typography>{children}</Typography>
			<YoiInput type={type} onInput={onInput} {...others} />
		</Box>
	);
};

export default SearchInput;
