import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSearchData } from "app/redux/states/forums/forums";
import { getUserForums } from "app/redux/states/forums/forums";
import axios from "axios.js";
const getDetails = async (id) => {
  let detailResponse = await axios.get(`/cms/api/v2/pages/${id}/`);
  let data = await detailResponse.data;

  return data;
};
const useForums = () => {
  const searchForums = async (queryParams) => {
    const params = {
      type: "cms.ThemeOfTheDayPage",
      fields: "*",
      ...queryParams,
    };
    try {
      const response = await axios.get(`/cms/api/v2/pages/`, { params });
      dispatch(addSearchData({ items: response.data.items }));
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();
  const status = useSelector((state) => state.forums.fetchStatus);
  const fetchUserForums = (username) =>
    dispatch(getUserForums({ username: username }));
  const userForums = useSelector((state) => state.forums.forums);

  return { fetchUserForums, userForums, status, getDetails, searchForums };
};

export default useForums;
