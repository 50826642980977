import {
  Box,
  Divider,
  Icon,
  IconButton,
  styled,
  InputAdornment,
  CircularProgress,
  Typography,
  // useTheme,
} from "@mui/material";
import MessageListContainer from "./MessageListContainer";
import MessageBubble from "./MessageBubble";
import { YoiInput } from "app/components/yoi";
import { FlexAlignCenter, FlexBetween } from "app/components/FlexBox";
import { Fragment, useState, useRef, useEffect } from "react";
import Scrollbar from "react-perfect-scrollbar";
import EmptyMessage from "./EmptyMessage";
import { useLang } from "app/hooks";
import { useSelector } from "react-redux";
const ChatRoot = styled(Box)(() => ({
  display: "flex",
  maxHeight: "100%",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  // Actual chat bg
  background: "rgba(0, 0, 0, 0.05)",
}));

const LeftContent = styled(FlexBetween)(({ theme }) => ({
  padding: "4px",
  background: "#2E2E2E",
}));

const UserName = styled("h5")(() => ({
  color: "#fff",
  fontSize: "16px",
  fontWeight: "500",
}));

const ScrollBox = styled(Scrollbar)(() => ({
  flexGrow: 1,
  position: "relative",
  alignSelf: "stretch",
  minHeight: "500px",
  maxHeight: "500px",
}));

const MessageBox = styled(FlexAlignCenter)(() => ({
  flexGrow: 1,
  height: "500px",
  flexDirection: "column",
}));

const ChatContainer = ({ title, userData, handleMessageSend, getNextMsgs }) => {
  const [replyId, setReplyId] = useState(null);
  const [message, setMessage] = useState("");
  const currentChat = useSelector((state) => state.contactChat.currentChat);
  const chatRef = useRef(null);
  const inputRef = useRef(null);
  const { content } = useLang();
  const chatContent = content?.chatContent;
  const handleMessage = () => {
    let tempMessage = message.trim();
    if (tempMessage !== "") handleMessageSend(tempMessage, replyId);
    setReplyId(null);
    setMessage("");
  };

  const sendMessageOnEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleMessage(message);
    }
  };

  const handleFetchOnTopScroll = async () => {
    getNextMsgs();
  };

  const unsetReply = () => setReplyId(null);

  return (
    <ChatRoot>
      <LeftContent>
        <Box display="flex" alignItems="center">
          {title && (
            <Fragment>
              <UserName>{title}</UserName>
            </Fragment>
          )}
        </Box>
      </LeftContent>
      <ScrollBox id="chat-message-list" ref={chatRef}>
        {!currentChat && (
          <MessageBox>
            <EmptyMessage />
            <p>{"Selecciona una conversación"}</p>
          </MessageBox>
        )}
        {/* {conversation && nextPage !== null && (
          <Typography
            textAlign={"center"}
            sx={{ cursor: "pointer", "&:hover": { color: "#7814E4" } }}
            onClick={() => handleFetchOnTopScroll()}
          >
            {chatContent.previousMessages}
          </Typography>
        )} */}
        <MessageListContainer />
      </ScrollBox>

      <Divider />

      {currentChat && (
        <Box px={2} py={1} display="flex" alignItems="center">
          <YoiInput
            fullWidth
            placeholder={chatContent.placeHolder}
            value={message}
            multiline={true}
            onKeyUp={sendMessageOnEnter}
            inputRef={inputRef}
            onChange={(e) => setMessage(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => handleMessage()}>
                    <Icon sx={{ color: "#7814E4" }}>send</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </ChatRoot>
  );
};

export default ChatContainer;
