import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { getWeek } from "app/utils/utils";
import { LABELS } from "app/consts/labels";

const { SUPPORT_GROUP, DAILY_THEME, SUGGESTION } = LABELS;
const baseEndpoint = `/referrers/api/referrers_user_graph/?`;

const endpoints = [
  `${baseEndpoint}conversation_type=support_group`,
  `${baseEndpoint}conversation_type=themeoftheday`,
  `${baseEndpoint}conversation_type=suggestion`,
];

export const getLineChartData = createAsyncThunk(
  "metricDetails/getLineChartData",
  async (data) => {
    const { username, unit } = data;
    const responses = endpoints.map((endpoint) =>
      axios.get(endpoint, { params: { username, unit } })
    );
    const [supportResponse, dailyThemeResponse, suggestionResponse] =
      await Promise.all(responses);
    return {
      supportGroup: supportResponse.data,
      dailyTheme: dailyThemeResponse.data,
      suggestion: suggestionResponse.data,
    };
  }
);

const linechartSlice = createSlice({
  name: "linechart",
  initialState: {
    lineChart: {
      loading: false,
      supportGroup: [],
      dailyTheme: [],
      suggestion: [],
    },
    fetchStatus: {
      error: {
        status: false,
        message: "",
      },
      loading: false,
      fulfilled: false,
    },
  },
  reducers: {
    processSocket: (state, action) => {
      const { payload } = action;
      if (payload.label === SUPPORT_GROUP) {
        const lastMetric = state.lineChart.supportGroup.pop();
        state.lineChart.supportGroup.push({
          ...lastMetric,
          y: lastMetric.y + payload.y,
        });
      }
      if (payload.label === DAILY_THEME) {
        const lastMetric = state.lineChart.dailyTheme.pop();
        state.lineChart.dailyTheme.push({
          ...lastMetric,
          y: lastMetric.y + payload.y,
        });
      }
      if (payload.label === SUGGESTION) {
        const lastMetric = state.lineChart.suggestion.pop();
        state.lineChart.suggestion.push({
          ...lastMetric,
          y: lastMetric.y + payload.y,
        });
      }
    },
  },
  extraReducers: (builder) => {
    // ! lineChart data reducers
    builder.addCase(getLineChartData.pending, (state, action) => {
      state.fetchStatus.error.status = false;
      state.lineChart.loading = true;
      state.fetchStatus.fulfilled = false;
    });
    builder.addCase(getLineChartData.rejected, (state, action) => {
      state.lineChart.loading = false;
      state.fetchStatus.error.status = true;
      state.fetchStatus.fulfilled = false;
    });
    builder.addCase(getLineChartData.fulfilled, (state, action) => {
      const supportGroup = action.payload.supportGroup.map((day) => ({
        x: day.x.split("T")[0],
        y: day.y,
      }));
      const dailyTheme = action.payload.dailyTheme.map((day) => ({
        x: day.x.split("T")[0],
        y: day.y,
      }));
      const suggestion = action.payload.suggestion.map((day) => ({
        x: day.x.split("T")[0],
        y: day.y,
      }));

      state.lineChart.supportGroup = getWeek(supportGroup).reverse();
      state.lineChart.dailyTheme = getWeek(dailyTheme).reverse();
      state.lineChart.suggestion = getWeek(suggestion).reverse();

      state.lineChart.loading = false;
      state.fetchStatus.fulfilled = true;
    });
    // ! End lineChart data reducers
  },
});

export const { processSocket } = linechartSlice.actions;

export default linechartSlice.reducer;
