import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./states/notification";
import suggestionsReducer from "./states/dashboard/suggestions";
import metricsReducer from "./states/dashboard/metrics";
import wisdomReducer from "./states/dashboard/wisdom";
import dailyReducer from "./states/dashboard/daily";
import growthReducer from "./states/dashboard/growth";
import metricsDetailReducer from "./states/metrics/metrics";
import yoiNotifications from "./states/dashboard/yoiNotifications";
import doughnutReducer from "./states/metrics/doughnut";
import linechartReducer from "./states/metrics/linechart";
import DailyChatReducer from "./states/chat/dailyChatSlice";
import suggestionDetails from "./states/suggestions/suggestionDetails";
import supportGroups from "./states/supportGroups/supportGroups";
import forumsReducer from "./states/forums/forums";
import contactChatSlice from "./states/chat/contactChatSlice";
import files from "./states/files/files";
export default configureStore({
  reducer: {
    metrics: metricsReducer,
    suggestions: suggestionsReducer,
    wisdom: wisdomReducer,
    dailyTheme: dailyReducer,
    yoiNotifications: yoiNotifications,
    growth: growthReducer,
    notifications: notificationReducer,
    metricsDetail: metricsDetailReducer,
    doughnutChart: doughnutReducer,
    lineChart: linechartReducer,
    dailyChat: DailyChatReducer,
    suggestionDetails: suggestionDetails,
    supportGroups: supportGroups,
    forums: forumsReducer,
    contactChat: contactChatSlice,
    files,
  },
});
