import useAuth from "app/hooks/useAuth";
import { CircularProgress } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import { routeNames } from "routes/routeIndex";
const ROLES = ["referente", "agente"];
const AuthGuard = ({ roleVerification, children }) => {
  const { isAuthenticated, authenticating } = useAuth();
  const { pathname } = useLocation();
  if (authenticating) return <CircularProgress />;
  return (
    <>
      {isAuthenticated ? (
        children
      ) : (
        <Navigate
          replace
          to={routeNames.auth.login}
          state={{ from: pathname }}
        />
      )}
    </>
  );
};

export default AuthGuard;
