import { Box, Typography, Tooltip } from "@mui/material";

const Date = ({ time, content }) => {
  return (
    <Tooltip title={content}>
      <Box
        px={2}
        width="100%"
        py={1}
        bgcolor="#C7C7C7"
        display="flex"
        alignItems={"center"}
        borderRadius={"5px"}
        gap={0.5}
      >
        <Typography fontWeight={700}>{time || null}</Typography>
        <Typography
          textOverflow={"ellipsis"}
          whiteSpace="nowrap"
          maxWidth="30ch"
          overflow={"hidden"}
        >
          {content}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default Date;
