// We handle actions on socket by its ref, in that way we know which action
// has been made, and this are the defined refs

export const SOCKET_CASES = {
	YOI_GROWTH: "user_count",
	USER_GROWTH: "chat_users_count",
	SHARE: "share_count",
	THEME_CHAT: "theme_user_count",
	THEME_FORUM: "add_conversation",
};

export const SUGGESTION_CASES = {
	LIKED_SUGGESTION: "SmaxL",
	COMMENTED_SUGGESTION: "SmaxC",
	SHARED_SUGGESTION: "SmaxS",
};

export const DAILY_THEME_CASES = {
	RATED_DAILY: "TDmaxV",
	COMMENTED_DAILY: "TDmaxC",
	SHARED_DAILY: "TDmaxSS",
};

export const SUPPORT_GROUP_CASES = {
	MOST_USERS: "GmaxU",
	LESS_USERS: "GminU",
	MOST_LIKED: "GmaxS",
};
