import * as React from "react";
import { Outlet } from "react-router-dom";
import { Main, DrawerHeader, drawerStyles } from "./DrawerAssets";
import { useAuth } from "app/hooks";
import Navbar from "./YoiNav";
import { useNavigate } from "react-router-dom";
import {
  styled,
  Box,
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { routeNames } from "routes/routeIndex";

const IconImg = styled("img")({
  width: "40px",
  height: "40px",
  objectPosition: "center",
  objectFit: "fit",
});
let baseImg = "/assets/yoi/sidebar/";

// {
//   icon: baseImg + "admin.png",
//   route: routeNames.yoiDashboard.dashboard,
//   title: "Admin",
// },
// {
//   icon: baseImg + "content.png",
//   route: routeNames.yoiDashboard.dashboard,
//   title: "Contenido",
// },
const SidebarRoutes = [
  {
    icon: baseImg + "operations.png",
    route: routeNames.yoiDashboard.notice,
    title: "Comunicados",
  },
  {
    icon: baseImg + "contact.png",
    route: routeNames.yoiDashboard.contact,
    title: "Contacto",
  },
];

export default function PersistentDrawerLeft({ children }) {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar open={open} handleDrawer={handleDrawer} user={user} />
      <Drawer height="350px" {...drawerStyles} open={open}>
        <DrawerHeader>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <IconImg src={baseImg + "newlogo.png"} />
          </Box>
        </DrawerHeader>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'space-evenly',
            //justifyContent: "space-between",
            height: "70%",
            // mt: "60px", 
          }}
        >
          {SidebarRoutes.map((icon, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => navigate(icon.route)}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Tooltip title={icon.title}>
                  <IconImg src={icon.icon} alt={icon.title} />
                </Tooltip>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
