import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToast } from "./redux";
import useQckChat from "./useQckChat";
import { setContactStatus } from "app/redux/states/chat/contactChatSlice";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { setNewMessage } from "app/redux/states/chat/contactChatSlice";
import useAuth from "./useAuth";
import { ws, sendText } from "./quickchat/websocket";
import useSound from "./useSound";
/**
 * 
 * @returns {Object} {sendMessage, sendText, handleMessage, changeChat, currentChat}
 * sendMessage: function to send a message to the websocket
 * sendText: function to send a text message to the websocket
 * handleMessage: function to handle a message received from the websocket
 * changeChat: function to change the current chat
 * currentChat: the current chat
 * 
 * {
  "id": 0,
  "time": 1678835902312,
  "reactions": "0001,0000,0000,0000,0000,0000,0000,0000",
  "senderType": "kSingleUser",
  "senderName": "Luis Manuel",
  "senderID": "SU00000001",
  "receiverType": "kSingleUser",
  "receiverName": "Carlos Zorro",
  "receiverID": "SU00000002",
  "content": {
      "text": "Buena noches",
      "issueNumber": 0,
      "chatContentClass": "quick_chat.adapters.chat.TextMessage"
  }
},
 */

// create a funcion that reproduces a sound when a new message is received
const useContactChat = () => {
  // Create a websocket connection with ws url
  const { user } = useAuth();
  const success = useSound("first");
  const send = useSound("second");
  const { createToast } = useToast();
  const { getChatContent } = useQckChat();
  const dispatch = useDispatch();
  const currentChat = useSelector((state) => state.contactChat.currentChat);
  const changeChat = (contact) => {
    getChatContent(contact);
  };
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("accessToken")
  );
  const connectionMsg = {
    type: "CONNECT",
    registryUID: user.UUID,
    token: authToken,
  };
  const { lastMessage, sendMessage, readyState } = useWebSocket(ws, {
    onOpen: () => {
      sendMessage(JSON.stringify(connectionMsg));
    },
  });
  const handleMessage = (message, userData, currentChat) => {
    let receiver = {
      id: currentChat.id.toUpperCase(),
      name: currentChat.username,
    };
    let sender = {
      id: user.UUID.toUpperCase(),
      name: user.username,
    };
    sendMessage(sendText(message, receiver, sender));
  };
  // create use effect to listen to the last message received
  useEffect(() => {
    if (!readyState === 1) return;
    if (!lastMessage) return;

    const data = lastMessage.data;
    if (data === "TIME") return;
    if (data.startsWith("Hello")) {
      // create a toast to notify the user that the connection was successful
      createToast("Conectado");
      return;
    }
    if (data.startsWith("ONLINE")) {
      // This event handles when an user is online or not;
      let [id, status] = data.substring(7).split(":");
      status = status === "true";
      dispatch(setContactStatus({ id, status }));
      return;
    }
    if (data.startsWith("OBJECT:")) {
      const parsedData = JSON.parse(data.substring(7));
      dispatch(setNewMessage({ message: parsedData, id: parsedData.senderID }));
      success();
      return;
    }
    if (data.startsWith("DISCUSSION:")) {
      // This events handles when a new contact is added;

      console.log("new contact", data.substring(11));
      return;
    }
    if (data.startsWith("OBJ_AKN:")) {
      // This event handles when your own message is sent;
      const data = JSON.parse(lastMessage.data.substring(8));
      dispatch(setNewMessage({ message: data, id: currentChat.id }));
      send();
      return;
    }
    console.log(lastMessage);
  }, [lastMessage, readyState]);
  return { sendMessage, sendText, handleMessage, changeChat, currentChat };
};

export default useContactChat;
