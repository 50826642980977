import { Box, Toolbar, IconButton, Typography } from "@mui/material";
import { Menu as MenuIcon, House, Notifications } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { AppBar } from "./DrawerAssets";
import { useState } from "react";
import { useAuth, useLang } from "app/hooks";
import Menu from "./menu/Menu";
const hoverStyles = {};

const UserIcon = styled("img")({
  cursor: "pointer",
  height: "40px",
  width: "40px",
  borderRadius: "50px",
  border: "1px solid black",
});
const UserBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  zIndex: 9999,
});

const defaultImg = "/assets/yoi/default.jpeg";

const YoiNav = ({ open, handleDrawer, user }) => {
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const isOpen = Boolean(isMenuOpen);
  const { content } = useLang();
  const navContent = content?.nav;
  const navigate = useNavigate();
  const handleClick = (event) => {
    setIsMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsMenuOpen(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };
  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{ backgroundColor: "#fafafa", color: "black" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 3 }}>
          {/* <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
          >
            <MenuIcon sx={{ color: "#8C8C8C" }} />
          </IconButton> */}
          <IconButton edge="start" onClick={() => navigate("/dashboard")}>
            <House sx={{ color: "#8C8C8C" }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", gap: 3 }}>
          {/* <IconButton>
            <Notifications sx={{ color: "#8C8C8C" }} />
          </IconButton> */}
          <UserBox
            id="menu"
            onClick={handleClick}
            color={isOpen ? "white" : "inherit"}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                p: 1,
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="14px"
                noWrap
                component="div"
                sm={{ display: "none" }}
                maxWidth={"15ch"}
              >
                {navContent?.msg}, {user.first_name}
              </Typography>
              <UserIcon src={user.photo || defaultImg} alt="User image" />
            </Box>
            <Menu isOpen={isOpen} handleClose={handleClose} logout={logout} />
          </UserBox>
        </Box>
      </Toolbar>
      {/* BG Overlay */}
      {isOpen && (
        <Box
          position="absolute"
          width="110vw"
          height="100vh"
          onClick={handleClose}
          sx={{
            backgroundColor: "rgba(46, 46, 46, 0.7)",
            top: 0,
            left: "-80px",
          }}
        />
      )}
    </AppBar>
  );
};

export default YoiNav;
