import React from "react";
import { Box, Typography } from "@mui/material";
const DateTag = ({ date }) => {
  // transform date from "2021-09-01" to "1 de septiembre" using javascript dates
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  date = `${day} de ${month}`;

  return (
    <Box px={2} py={1} bgcolor="#F81E38" borderRadius="5px">
      <Typography color="white">{date}</Typography>
    </Box>
  );
};

export default DateTag;
