import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchSupportGroups,
	postSupportGroup,
	addSearchData,
} from "app/redux/states/supportGroups/supportGroups";
import axios from "axios.js";
import useAuth from "app/hooks/useAuth";

const useSupportGroups = () => {
	const { user } = useAuth();
	const dispatch = useDispatch();
	const supportGroups = useSelector((state) => state.supportGroups.data);
	const searchSupport = async (queryParams) => {
		const params = {
			...queryParams,
		};
		const response = await axios.get(`/referrers/api/support_groups_detail/`, {
			params,
		});
		const conversationResponse = await axios.get(
			"/referrers/api/support_groups/"
		);
		const processedResponse = response.data.map((group) => {
			const conversation = conversationResponse.data.find(
				(groupConversation) => groupConversation.name === group.name
			);
			return { ...group, conversation: conversation.conversation };
		});
		dispatch(addSearchData(processedResponse));
	};
	const status = useSelector((state) => state.supportGroups.fetchStatus);
	const getSupportGroups = (queryParams = {}) =>
		dispatch(
			fetchSupportGroups({
				queryParams: { ...queryParams, owner_user_username: user.username },
			})
		);
	const createSupportGroup = (form) => dispatch(postSupportGroup(form));

	useEffect(() => {
		if (status.fulfilled) return;
		getSupportGroups();
	}, []);

	return {
		getSupportGroups,
		createSupportGroup,
		searchSupport,
		supportGroups,
		status,
	};
};

export default useSupportGroups;
