const sounds = {
  first:
    "https://tc.yoifirst.com/QuickChat/sounds/notifications-sound-127856.mp3",
  second: "https://tc.yoifirst.com/QuickChat/sounds/notification-126507.mp3",
  error:
    "https://tc.yoifirst.com/QuickChat/sounds/error-call-to-attention-129258.",
};

const useSound = (url) => {
  if (!url) return;
  if (!sounds[url]) return;
  const audio = new Audio(sounds[url]);
  const play = () => {
    audio.play();
  };
  return play;
};

export default useSound;
