import { useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { refresh } from "app/contexts/JWTAuthContext";

const useSocketData = (url, options) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const { lastJsonMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_SOCKET_URL}${url}`,
    {
      shouldReconnect: () => true,
      ...options,
      queryParams: {
        token: accessToken || "",
        ...options?.queryParams,
      },
      // onError: (event) => {
      //   (async () => {
      //     console.log(event);
      //     const newAccessToken = await refresh();
      //     setAccessToken(newAccessToken);
      //   })();
      // },
    }
  );

  return {
    lastJsonMessage,
    readyState,
  };
};

export default useSocketData;
