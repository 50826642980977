import { createContext, useEffect, useState } from "react";
import { supportedLanguages, defaultLanguage } from "./languageSettings";

export const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState({});
  const [userLanguage, setUserLanguage] = useState(null);

  const validateLanguage = (lang) => {
    // Validates if language is supported, if it is not it sets spanish as default language
    if (!supportedLanguages.includes(lang)) {
      return defaultLanguage;
    }
    return lang;
  };
  const updateUserLanguage = async (lang) => {
    const validatedLanguage = validateLanguage(lang);
    setUserLanguage(validatedLanguage);
    localStorage.setItem("language", validatedLanguage);
    await updateLanguage();
  };

  const updateLanguage = async () => {
    const content = await import(`languages/${userLanguage}/content.js`);
    const notifications = await import(
      `languages/${userLanguage}/notifications.js`
    );
    setLanguage({
      content: content.default,
      notifications: notifications.default,
    });
  };

  // Sets user preffered languaged based on browser settings or previously stored data
  useEffect(() => {
    const userLanguageChoice = localStorage.getItem("language");
    // navigator.language es-ES. we split by the - and grab "es"
    const browserLanguage = navigator.language.split("-")[0];
    if (!userLanguageChoice) {
      localStorage.setItem("language", validateLanguage(browserLanguage));
    }
    setUserLanguage(userLanguageChoice || validateLanguage(browserLanguage));
  }, []);

  // Sets language context for
  useEffect(() => {
    if (!userLanguage) return;
    updateLanguage();
  }, [userLanguage]);
  return (
    <LanguageContext.Provider
      value={{
        actions: { updateUserLanguage },
        content: language.content,
        notifications: language.notification,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
export default LanguageProvider;
