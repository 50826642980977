import { Typography, Box } from "@mui/material";
import Icon from "../../../views/yoi-dashboard/components/Icon";

const Title = ({ icon, children }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Icon src={icon} />
      <Typography fontSize="40px" color="#C7C7C7" fontWeight={700}>
        {children}
      </Typography>
    </Box>
  );
};

export default Title;
