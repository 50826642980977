import axios from "axios";

const baseUrl = "https://api.nylas.com";
const authToken = "eIm35JkXdvpt9tqJd5Igsez9relL8P";
export const nylasClient = "71vpbigy5lcy7y1j7ovgezqe0";

// create an axios provider that uses the base url and auth token as param

export const nylasUser = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

const clientSecret = "1y8yk0othae58uj7u185kuxgx:";
export const nylasAdmin = axios.create({
  baseURL: baseUrl,
  auth: {
    username: clientSecret,
  },
  // Prevent cors errors
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
