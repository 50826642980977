import React from "react";
import { Box, Grid, styled } from "@mui/material";
import DateTag from "../DateTag";
const BASE_URL = "https://d1ohk3tot2fym6.cloudfront.net/";

const FileImg = styled("img")({
  width: "100%",
});

const fileTypes = {
  file: "assets/yoi/contact/file.png",
  img: "assets/yoi/contact/img.png",
  voice: "assets/yoi/contact/voice.png",
};

const imgs = ["jpeg", "jpg", "png"];

const mockFiles = [
  {
    type: "file",
  },
  {
    type: "img",
  },
  {
    type: "file",
  },
  {
    type: "voice",
  },
];

const getFileType = (file) => {
  const { path } = file;
  // get file extension splitting the string based on QuickChat/Octavio/Brian/Republican.png
  const extension = path.split("/").pop().split(".").pop();
  if (imgs.includes(extension)) {
    return "img";
  }
};

const FileList = ({ files = mockFiles }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3} pb={2}>
        {files.map((fileDate, index) => (
          <>
            <Grid item md={12}>
              <Box display="flex" justifyContent="center" mb={2}>
                <DateTag date={fileDate.date} />
              </Box>
            </Grid>
            {fileDate.files.map((file, index) => (
              <Grid
                item
                key={`${file.type}-${index}`}
                md={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {getFileType(file) === "img" ? (
                  <FileImg src={BASE_URL + file.path} />
                ) : (
                  <FileImg src={fileTypes[getFileType(file)]} />
                )}
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default FileList;
