import React from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import YoiInput from "../../Input";
import { YoiButton } from "../../misc";
import { Formik } from "formik";
import { useAuth, useToast } from "app/hooks";
import axios from "axios.js";
import { useState } from "react";
const inputControlStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: "70%",
  gap: 1,
  "& p": {
    width: "80px",
  },
};
const ProfileForm = ({ handleClose }) => {
  const [loading, setLoading] = useState(false);
  const { createToast } = useToast();
  const { user, setUserData } = useAuth();
  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `/api/users_profile/${user.username}/`,
        {
          first_name: values.name || user.first_name,
          birthday: values.date || user.birthday,
          sex: values.gender.toUpperCase() || user.sex,
          profile_description: values.about || user.profile_description,
        }
      );
      if (response.status == 200 || response.status == 201) {
        createToast("Perfil actualizado con exito!", "success");
        setUserData();
        handleClose();
      }
    } catch (error) {
      createToast(error, "error");
    }
    setLoading(false);
  };
  return (
    <Box>
      <Typography fontSize="32px" textAlign={"center"} fontWeight={"700"}>
        Perfil
      </Typography>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          name: user?.first_name,
          referrerCode: user?.referrer_code,
          gender: user?.sex === "F" ? "F" : user?.sex === "M" ? "M" : null,
          date: user.birthday,
          about: user?.profile_description,
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
                gap: 3,
              }}
            >
              <Box sx={inputControlStyles}>
                <Typography>Nombre</Typography>
                <YoiInput
                  onBlur={handleBlur}
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  sx={{ width: "300px" }}
                />
              </Box>
              <Box sx={inputControlStyles}>
                <Typography>Codigo de referente</Typography>
                <YoiInput
                  disabled
                  onChange={handleChange}
                  value={user.referrer_code || "Don't have"}
                  sx={{ width: "300px" }}
                />
              </Box>
              <Box sx={inputControlStyles}>
                <Typography>Fecha de nacimiento</Typography>
                <Box sx={{ display: "flex", gap: 4 }}>
                  <YoiInput
                    name="date"
                    type="date"
                    onChange={handleChange}
                    value={values.date}
                    sx={{ width: "300px" }}
                  />
                </Box>
              </Box>
              <Box sx={inputControlStyles}>
                <Typography>Sexo</Typography>
                <YoiInput
                  select
                  sx={{ width: "120px" }}
                  name="gender"
                  defaultValue={values.gender}
                  onChange={handleChange}
                >
                  <MenuItem value={"M"}>Masculino</MenuItem>
                  <MenuItem value={"F"}>Femenino</MenuItem>
                </YoiInput>
              </Box>
              <Box sx={inputControlStyles}>
                <Typography>Acerca de mi</Typography>
                <YoiInput
                  multiline
                  name="about"
                  rows={3}
                  value={values.about}
                  onChange={handleChange}
                  type="text"
                  sx={{ width: "300px" }}
                />
              </Box>
              <YoiButton
                loading={loading}
                type="submit"
                sx={{ width: "300px" }}
              >
                Aceptar
              </YoiButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProfileForm;
