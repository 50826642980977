import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchMetrics } from "app/redux/states/dashboard/metrics";
import useSocketData from "../../useSocketData";
import { processSocketMetric } from "app/redux/states/dashboard/metrics";
import useAuth from "app/hooks/useAuth";

const useMetrics = () => {
	const { user } = useAuth();
	const dispatch = useDispatch();
	const fetchStatus = useSelector((state) => state.metrics.fetchStatus);
	const yoiMetrics = useSelector((state) => state.metrics.yoiMetrics);
	const userMetrics = useSelector((state) => state.metrics.userMetrics);
	const metrics = useSelector((state) => state.metrics.processedMetrics);
	const metricsReduced = useSelector((state) => state.metrics.reducedMetrics);
	const { lastJsonMessage, readyState } = useSocketData("/ws/user_graph/");
	const getMetrics = (unit = "week", username) => {
		dispatch(fetchMetrics({ unit, username }));
	};

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		dispatch(processSocketMetric(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	useEffect(() => {
		if (fetchStatus.fulfilled) return;
		getMetrics("day", user.username);
	}, []);

	return {
		getMetrics,
		yoiMetrics,
		userMetrics,
		metrics,
		metricsReduced,
		fetchStatus,
	};
};

export default useMetrics;
