import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios.js";

export const fetchSupportGroups = createAsyncThunk(
  "supportGroups/getinitial",
  async (data) => {
    const params = {
      ...data.queryParams,
    };
    const response = await axios.get(`/referrers/api/support_groups_detail/`, {
      params,
    });
    const conversationResponse = await axios.get(
      "/referrers/api/support_groups/"
    );
    const processedResponse = response.data.map((group) => {
      const conversation = conversationResponse.data.find(
        (groupConversation) => groupConversation.name === group.name
      );
      return { ...group, conversation: conversation.conversation };
    });
    return processedResponse;
  }
);
function removeDuplicates(arr) {
  const strigified = arr.map((item) => JSON.stringify(item));
  const removed = [...new Set(strigified)];
  return removed.map((item) => JSON.parse(item));
}

export const postSupportGroup = createAsyncThunk(
  "supportGroups/post",
  async (data) => {
    const response = await axios.post("/chat/api/support_group/", {
      ...data,
      category: 1,
    });
    return response.data;
  }
);

const supportGroupsSlice = createSlice({
  name: "supportGroups",
  initialState: {
    data: [],
    fetchStatus: {
      error: false,
      loading: false,
      fulfilled: false,
    },
  },
  reducers: {
    addSearchData: (state, action) => {
      let extendedArray = [...current(state.data), ...action.payload];
      state.data = removeDuplicates(extendedArray);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupportGroups.pending, (state, action) => {
      state.fetchStatus.loading = true;
      state.fetchStatus.error = false;
    });
    builder.addCase(fetchSupportGroups.fulfilled, (state, action) => {
      state.data = action.payload;
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = true;
    });
    builder.addCase(fetchSupportGroups.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.error = true;
    });
    builder.addCase(postSupportGroup.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = true;
    });
  },
});

export const { addSearchData } = supportGroupsSlice.actions;

export default supportGroupsSlice.reducer;
