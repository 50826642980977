import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useLang } from "app/hooks";

const BackBtn = styled(Button)({
  border: "1px solid white",
  width: "150px",
  height: "30px",
  color: "white",
  borderRadius: "10px",
});

const BackButton = () => {
  const navigate = useNavigate();
  const { content } = useLang();
  return <BackBtn onClick={() => navigate(-1)}>{content?.backBtn}</BackBtn>;
};

export default BackButton;
