import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
let endpoint = "/referrers/api/notification/";

export const fetchYoiNotifications = createAsyncThunk(
  "yoi/notifications",
  async () => {
    const response = await axios.get(endpoint);
    return response.data;
  }
);

export const processSocketNotification = createAsyncThunk(
  "yoi/socket-notification",
  async (notification) => {
    return notification;
  }
);

const yoiNofiticationSlice = createSlice({
  name: "yoiNotifications",
  initialState: {
    notifications: [],
    count: null,
    fetchStatus: {
      error: {
        status: false,
      },
      loading: false,
      fulfilled: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchYoiNotifications.pending, (state, action) => {
      state.fetchStatus.loading = true;
      state.fetchStatus.fulfilled = false;
    });
    builder.addCase(fetchYoiNotifications.fulfilled, (state, action) => {
      state.fetchStatus.loading = false;
      state.count = action.payload.count;
      state.fetchStatus.fulfilled = true;
      state.notifications = action.payload.results;
    });
    builder.addCase(fetchYoiNotifications.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.error.status = true;
    });
    builder.addCase(processSocketNotification.fulfilled, (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
      state.count += 1;
    });
  },
});

export default yoiNofiticationSlice.reducer;
