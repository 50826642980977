import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
let endpoint =
  "/cms/api/v2/pages/?type=cms.SuggestionPage&limit=3&order=-share_count&fields=_,id,header_image,title,abstract,autor,share_count";

let postEndpoint = "/cms/api/v2/extra/share/";
export const fetchSuggestions = createAsyncThunk(
  "suggestions/getSuggestions",
  async () => {
    let response = await axios.get(endpoint);
    return response.data.items;
  }
);

export const postSuggestion = createAsyncThunk(
  "suggestions/postSuggestion",
  async (id) => {
    let response = await axios.post(postEndpoint, {
      content_type: "cms.SuggestionPage",
      object_pk: id,
    });
    return response;
  }
);

const suggestionSlice = createSlice({
  name: "suggestions",
  initialState: {
    suggestions: [],
    fetchStatus: {
      loading: false,
      fulfilled: false,
      error: {
        status: false,
        message: "",
      },
    },
  },
  reducers: {
    changeSharedCount: (state, action) => {
      const { payload } = action;
      const index = state.suggestions.findIndex(
        (suggestion) => suggestion.id === payload.object_pk
      );
      state.suggestions[index] = {
        ...state.suggestions[index],
        share_count: state.suggestions[index].share_count + payload.count,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuggestions.pending, (state, action) => {
      state.fetchStatus.fulfilled = false;
      state.fetchStatus.error.status = false;
      state.fetchStatus.loading = true;
    });
    builder.addCase(fetchSuggestions.fulfilled, (state, action) => {
      state.fetchStatus.loading = false;
      state.suggestions = action.payload;
      state.fetchStatus.fulfilled = true;
    });
    builder.addCase(fetchSuggestions.rejected, (state, action) => {
      state.fetchStatus.loading = false;
      state.fetchStatus.fulfilled = false;
      state.fetchStatus.error.status = true;
    });
    builder.addCase(postSuggestion.fulfilled, (state, actions) => {});
  },
});

export const { changeSharedCount } = suggestionSlice.actions;

export default suggestionSlice.reducer;
