import { MatxLoading } from "app/components";
import { useLang } from "app/hooks";
import axios from "axios.js";
import { useNavigate } from "react-router-dom";
// import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import { routeNames } from "routes/routeIndex";
import { calcUserCode } from "app/hooks/quickchat/websocket";
import { useToast } from "app/hooks";
const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  authenticating: false,
};

export const refresh = async (token) => {
  try {
    const refreshToken = token ?? localStorage.getItem("refreshToken");
    const requestBody = { refresh_token: refreshToken };
    const response = await axios.post(
      "/paseto_auth/token/refresh/",
      requestBody
    );
    localStorage.setItem("accessToken", response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

const getSetSession = () => {
  let responseInterceptor;
  let requestInterceptor;
  return (accessToken, refreshToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      requestInterceptor = axios.interceptors.request.use(
        (config) => {
          if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
      responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const prevRequest = error?.config;
          if (error?.response?.status === 403 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axios(prevRequest);
          }
          return Promise.reject(error);
        }
      );
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isAuthenticated: true,
        isInitialised: true,
        authenticating: false,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true, authenticating: false };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null, isInitialised: true };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    case "REFRESH": {
      return { ...state, isAuthenticated: true };
    }
    case "AUTHENTICATING": {
      return { ...state, authenticating: true };
    }
    case "AUTHENTICATED": {
      return { ...state, authenticating: false };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => { },
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { content } = useLang();
  const { createToast } = useToast();
  const navigate = useNavigate();
  const setSession = getSetSession();

  const setUserData = async () => {
    const userResponse = await axios.get("/api/users/me/");
    const groupResponse = await axios.get("/api/users/me/groups/");
    if (groupResponse.data.groups.includes("Referentes")) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...userResponse.data,
            groups: groupResponse.data.groups,
            role: "referente",
            UUID: calcUserCode("R", userResponse.data.id).toUpperCase(),
          },
        },
      });
      return "success referrente";
    } else if (groupResponse.data.groups.includes("Agentes")) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...userResponse.data,
            groups: groupResponse.data.groups,
            role: "agente",
            UUID: calcUserCode("A", userResponse.data.id).toUpperCase(),
          },
        },
      });
      return "success agent";
    } else {
      setSession("");
      return content?.login?.isNotReferente;
    }
  };

  const login = async (email, password) => {
    const { data } = await axios.post("/paseto_auth/token/", {
      email,
      password,
    });

    const { access_token: accessToken, refresh_token: refreshToken } = data;
    setSession(accessToken, refreshToken);
    return setUserData();
  };

  const register = async (email, username, password) => {
    const { data } = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = data;
    setSession(accessToken);
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const passwordRecovery = async (email) => {
    setSession();
    try {
      const response = await axios.post("/api/restore_token/", {
        email: email.email,
        rol: "referentes",
      });

      if (response.status === 201) {
        return response;
      }
      return false;
    } catch (error) {
      return error;
    }
  };

  const passwordReset = async (data) => {
    try {
      const response = await axios.post("/api/restore_password/", {
        email: data.email,
        token: data.token,
        password: data.password,
        password2: data.passwordConfirm,
      });
      return response;
    } catch (error) {
      return { error: error };
    }
  };
  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const facebookLogin = async (data) => {
    try {
      setSession();
      const response = await axios.post("/dj-rest-auth/facebook/login/", {
        access_token: data.access_token,
        id_token: data.id_token,
      });
      const { access_token: accessToken } = response.data;
      setSession(accessToken);

      const userResponse = await axios.get("/api/users/me/");
      if (userResponse.data.is_referrer) {
        dispatch({ type: "LOGIN", payload: { user: userResponse.data } });
        return "success";
      } else {
        setSession("");
        return content?.login?.isNotReferente;
      }
    } catch (error) {
      return error;
    }
  };
  const tokenRefreshers = async () => {
    try {
      // Local storage is used to persist the refresh token
      const lsRefreshToken = window.localStorage.getItem("refreshToken");
      const requestBody = { refresh_token: lsRefreshToken };
      const response = await axios.post(
        "/paseto_auth/token/refresh/",
        requestBody
      );
      const { access_token: accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      return { accessToken };
    } catch (error) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      dispatch({ type: "LOGOUT" });
      setSession();
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const localRefreshToken = window.localStorage.getItem("refreshToken");
        if (localRefreshToken && !state.isAuthenticated) {
          dispatch({ type: "AUTHENTICATING" });
          const { accessToken } = await tokenRefreshers();
          setSession(accessToken, localRefreshToken);

          const response = await axios.get("/api/users/me/", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const groupResponse = await axios.get("/api/users/me/groups/", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const userData = await response.data;
          const groupData = await groupResponse.data;
          const UUID = calcUserCode(
            groupData.groups.includes("Agentes") ? "A" : "R",
            userData.id
          ).toUpperCase();
          const user = {
            ...userData,
            groups: groupData.groups,
            role: groupData.groups.includes("Agentes") ? "agente" : "referente",
            UUID,
          };

          dispatch({
            type: "INIT",
            payload: { user },
          });
        } else {
          dispatch({ type: "AUTHENTICATED" });
          dispatch({
            type: "LOGOUT",
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        dispatch({ type: "AUTHENTICATED" });
        dispatch({
          type: "LOGOUT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        setUserData,
        passwordRecovery,
        passwordReset,
        facebookLogin,
        tokenRefresher: tokenRefreshers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
