import { Navigate, useLocation } from "react-router-dom";
import { routeNames } from "routes/routeIndex";
const Redirect = () => {
  let location = useLocation();
  // Get redirect location or provide fallback
  const from = location.state?.from || routeNames.yoiDashboard.dashboard;

  // in auth callback logic, once authenticated navigate (redirect) back
  // to the route originally being accessed.
  return <Navigate to={from} />;
};

export default Redirect;
