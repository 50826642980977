export const routeNames = {
  auth: {
    login: "/session/signin",
    passwordRecovery1: "/session/forgot-password",
    passwordRecovery2: "/session/reset-password",
    sessions404: "/session/404",
  },
  yoiDashboard: {
    dashboard: "/dashboard",
    metrics: "/metrics",
    dailyTheme: "/daily",
    suggestions: "/suggestions",
    suggestionDetails: "/suggestion/detail",
    supportGroups: "/support-groups",
    forums: "/forums",
    contact: "/contact",
    notice: "/notice",
  },
};
