import { useEffect } from "react";
import useAuth from "app/hooks/useAuth";
import useSocketData from "app/hooks/useSocketData";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios.js";
import {
  addChatMessage,
  loadReplies,
  setStart,
  addUserToConversation,
} from "../../../redux/states/chat/dailyChatSlice";
import {
  getChatData,
  getNewData,
} from "../../../redux/states/chat/dailyChatSlice";

const useDailyChat = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const dailyTheme = useSelector((state) => state.dailyTheme.dailyTheme);
  const dailyChat = useSelector((state) => state.dailyChat);

  const { conversation, messages, userList, next } = useSelector(
    (state) => state.dailyChat
  );

  const { fulfilled, error, loading } = useSelector(
    (state) => state.dailyChat.fetchStatus
  );
  const replyStatus = useSelector((state) => state.dailyChat.replyStatus);
  const setReplies = (data) => dispatch(loadReplies(data));
  const handleStart = (id, loading) => dispatch(setStart(id, loading));
  const addMessage = (message) => dispatch(addChatMessage(message));
  const addUser = (user) => dispatch(addUserToConversation(user));


  const getDailyChatUUID = (id) => {
    dispatch(getChatData({ id, username: user.username }));
  };


  const getNextMsgs = () => {
    dispatch(getNewData({ id: conversation.conversation_id, next }));
  };

  const loadRepliesById = async (id, next) => {
    handleStart({ id: id, loading: true });
    const response = await axios.get(
      next
        ? `/chat/api/message/${id}/replies/?page=${next}`
        : `/chat/api/message/${id}/replies/`
    );
    setReplies({
      id,
      response: response.data.results,
      next: response.data.next,
    });
  };
  const { lastJsonMessage, readyState } = useSocketData(
    `/ws/chat/${conversation?.conversation_id}/`
  );

  // const createNewConversation = async (message) => {
  //   try {
  //     const createResponse = await axios.post(
  //       `/cms/api/v2/extra/themeoftheday-conversation/${dailyTheme.id}/`,
  //       {
  //         themeoftheday: dailyTheme.id,
  //         conversation_name: dailyTheme.title,
  //         message_content: message,
  //       }
  //     );
  //     console.log(createResponse);
  //     getDailyChatUUID(dailyTheme.id);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (loading) return;
    if (!dailyTheme.id) return;
    if (fulfilled) return;

    getDailyChatUUID(dailyTheme.id);
  }, [dailyTheme]);

  useEffect(() => {
    if (lastJsonMessage === null || readyState !== 1) return;
    if (lastJsonMessage?.type === "chat_message") {
      addMessage(lastJsonMessage.message);
    }
    if (lastJsonMessage?.type === "chat_conversation_user_joined") {
      addUser(lastJsonMessage.message.user_details);
      addMessage({
        posted_by_user: lastJsonMessage.message.user_details,
        message_content: {
          notMessage: true,
          user: lastJsonMessage.message.user,
        },
      });
    }
  }, [lastJsonMessage, readyState]);
  return {
    conversation,
    replyStatus,
    userList,
    messages,
    getDailyChatUUID,
    fulfilled,
    error,
    getNextMsgs,
    loading,
    // createNewConversation,
    loadRepliesById,
    nextPage: next,
  };
};

export default useDailyChat;
