import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { LABELS } from "app/consts/labels";

const { SUPPORT_GROUP, DAILY_THEME, SUGGESTION } = LABELS;

export const getDoughtnutData = createAsyncThunk(
	"doughnut/getdata",
	async (data) => {
		let doughnutData = {
			dailyTheme: 0,
			supportGroup: 0,
			suggestions: 0,
		};
		const response = await axios.get(
			`/referrers/api/pie_chart/?username=${data.username}`
		);
		response.data.forEach((item) => {
			if (item.label === DAILY_THEME)
				return (doughnutData.dailyTheme = item.number);
			if (item.label === SUGGESTION)
				return (doughnutData.suggestions = item.number);
			if (item.label === SUPPORT_GROUP)
				return (doughnutData.supportGroup = item.number);
		});
		return doughnutData;
	}
);

const doughnutSlice = createSlice({
	name: "doughnut",
	initialState: {
		data: {
			dailyTheme: 0,
			supportGroup: 0,
			suggestions: 0,
		},
		fetchStatus: {
			error: false,
			loading: false,
			fulfilled: false,
		},
	},
	reducers: {
		processSocket: (state, action) => {
			const { payload } = action;
			if (payload.label === DAILY_THEME) state.data.dailyTheme += payload.y;
			if (payload.label === SUGGESTION) state.data.suggestions += payload.y;
			if (payload.label === SUPPORT_GROUP) state.data.supportGroup += payload.y;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getDoughtnutData.pending, (state, action) => {
			state.fetchStatus.loading = true;
			state.fetchStatus.error = false;
		});
		builder.addCase(getDoughtnutData.rejected, (state, action) => {
			state.fetchStatus.loading = false;
			state.fetchStatus.error = true;
		});
		builder.addCase(getDoughtnutData.fulfilled, (state, action) => {
			state.data = action.payload;
			state.fetchStatus.loading = false;
			state.fetchStatus.fulfilled = true;
		});
	},
});

export const { processSocket } = doughnutSlice.actions;

export default doughnutSlice.reducer;
