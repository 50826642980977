import { red } from "@mui/material/colors";
import { components } from "./components";
import "@fontsource/poppins";
const themeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: `"Poppins"`,
    body1: { fontSize: "14px" },
  },

  status: { danger: red[500] },
  components: { ...components },
};

export default themeOptions;
