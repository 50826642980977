import { styled, Paper, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
const IconBackground = styled(Box)({
  backgroundColor: "white",
  borderRadius: "50px",
  width: "70px",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Img = styled("img")({
  width: "30px",
  height: "30px",
});
const IconContainer = styled(Paper)({
  width: "100px",
  height: "100px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEDEDE",
  boxShadow: "10px 10px 14px 0px #00000030",
});

const Icon = ({ src, route, label }) => {
  const navigate = useNavigate();
  return route ? (
    <Tooltip title={label} onClick={() => navigate(route)} sx={{}}>
      <IconContainer
        sx={{
          cursor: "pointer",

          ":hover": {
            boxShadow: "0px 0px 4px 0px #00000030",
          },
        }}
      >
        <IconBackground>
          <Img src={src} alt="dashboard item" />
        </IconBackground>
      </IconContainer>
    </Tooltip>
  ) : (
    <IconContainer>
      <IconBackground>
        <Img src={src} alt="dashboard item" />
      </IconBackground>
    </IconContainer>
  );
};

export default Icon;
