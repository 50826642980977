import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processSocketGrowth } from "app/redux/states/dashboard/growth";
import { useSocketData } from "app/hooks";
import { SOCKET_CASES } from "app/consts/socketCases";
import { changeSharedCount as changeSuggestionSharedCount } from "app/redux/states/dashboard/suggestions";
import { changeSharedCount as changeWisdomSharedCount } from "app/redux/states/dashboard/wisdom";
import { useAuth } from "app/hooks";

const useCurrentData = () => {
	const { user } = useAuth();
	const dispatch = useDispatch();
	const { lastJsonMessage, readyState } = useSocketData("/ws/share_and_user/", {
		queryParams: { referrer: user.username },
	});

	const suggestions = useSelector((state) => state.suggestions.suggestions);
	const wisdom = useSelector((state) => state.wisdom.dailyWisdom);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		if (lastJsonMessage.ref === SOCKET_CASES.SHARE) {
			const { object_pk: pageId } = lastJsonMessage;
			const isSuggestion = suggestions.some(
				(suggestion) => suggestion.id === pageId
			);

			if (isSuggestion)
				return dispatch(changeSuggestionSharedCount(lastJsonMessage));
			const isWisdom = wisdom.some((wisdomItem) => wisdomItem.id === pageId);
			if (isWisdom) return dispatch(changeWisdomSharedCount(lastJsonMessage));
			return;
		}
		dispatch(processSocketGrowth(lastJsonMessage));
	}, [lastJsonMessage, readyState]);
};

export default useCurrentData;
