import { Box, Divider, styled, CircularProgress } from "@mui/material";
import ChatAvatar from "app/components/ChatAvatar";
import { Paragraph } from "app/components/Typography";
import { format } from "date-fns";
import Scrollbar from "react-perfect-scrollbar";

const defaultImg = "/assets/yoi/default.jpeg";

const ChatSideNavRoot = styled("div")(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, 0.08)",
  height: "100%",

  background:
    "linear-gradient(127.62deg, #F81E38 7.47%, #F81E38 16.08%, #7814E4 84.81%, #7814E4 97.14%)",
}));

const StyledScrollBar = styled(Scrollbar)(() => ({
  height: "100%",
  position: "relative",
}));

const LeftContent = styled("div")(({ theme }) => ({
  height: 56,
  display: "flex",
  paddingLeft: "16px",
  gap: 5,
  paddingRight: "16px",
  alignItems: "center",
}));

const UserName = styled("h5")(() => ({
  color: "#fff",
  margin: "0px",
  padding: "0px",
  fontSize: "18px",
  fontWeight: "500",
}));

const ContactList = styled("div")(() => ({
  padding: "16px",
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  transition: "background 250ms ease",
  "&:hover": { background: "rgba(0, 0, 0, 0.054)" },
}));

const ChatSidenav = ({
  currentUser,
  contactList = [],
  handleContactClick,
  currentChat,
}) => {
  return (
    <ChatSideNavRoot>
      <LeftContent>
        <ChatAvatar
          src={currentUser.photo || defaultImg}
          status={currentUser.isOnline}
        />
        <UserName>{currentUser.username}</UserName>
      </LeftContent>

      <StyledScrollBar>
        <Divider />

        {contactList ? (
          contactList.map((contact, index) => (
            <ContactList
              key={contact.id}
              sx={{
                padding: "4px 16px",
                bgcolor:
                  contact === currentChat
                    ? "rgba(255, 255, 255, 0.3)"
                    : "rgba(255, 255, 255, 0)",
              }}
              onClick={() => handleContactClick(contact)}
            >
              <ChatAvatar
                src={contact.photo || defaultImg}
                status={contact.isOnline}
              />

              <Box pl={2} color="white">
                <p>{contact.username}</p>
              </Box>
            </ContactList>
          ))
        ) : (
          <CircularProgress />
        )}
      </StyledScrollBar>
    </ChatSideNavRoot>
  );
};

export default ChatSidenav;
