import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getDoughtnutData,
	processSocket,
} from "app/redux/states/metrics/doughnut";
import { useChartSocket } from "app/hooks";

const useDoughtnut = () => {
	const dispatch = useDispatch();
	const { lastJsonMessage, readyState } = useChartSocket();
	const doughnutData = useSelector((state) => state.doughnutChart.data);
	const status = useSelector((state) => state.doughnutChart.fetchStatus);
	const fetchDoughnutData = (data) => dispatch(getDoughtnutData(data));

	useEffect(() => {
		if (status.fulfilled) return;
		fetchDoughnutData();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		if (!("label" in lastJsonMessage)) return;
		dispatch(processSocket(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	return { doughnutData, status, fetchDoughnutData };
};

export default useDoughtnut;
