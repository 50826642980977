import React from "react";
import { Title } from "app/components/yoi";
import { useQuickChat } from "app/hooks";
import { Grid } from "@mui/material";
import AppChat from "./chat-box/AppChat";
import SharedFiles from "./shared/SharedFiles";
import Calendly from "./calendly/Calendly";
import ContactCards from "./ContactCards";
const Contact = () => {
  const { userData, getChatContent, contacts } = useQuickChat();
  return (
    <>
      <Grid container display={"flex"} justifyContent="space-between">
        <Grid item md={6}>
          <Title icon={"/assets/yoi/icons/contact.png"}>
            {"Contacto".toUpperCase()}
          </Title>
        </Grid>
        <Grid item md={6} sx={{ display: { xs: "none", xl: "block" } }}>
          <ContactCards />
        </Grid>
      </Grid>
      <Grid container my={5} spacing={3} minHeight="500px">
        <Grid item sm={8} xl={6}>
          <AppChat
            userList={contacts}
            getChatContent={getChatContent}
            userData={userData}
          />
        </Grid>
        <Grid
          item
          sm={4}
          xl={3.5}
          sx={{ display: { xs: "none", xl: "block" } }}
        >
          <SharedFiles />
        </Grid>
        <Grid item sm={4} xl={2.5}>
          <Calendly />
        </Grid>
        <Grid
          item
          sm={8}
          xl={3.5}
          sx={{ display: { xs: "block", xl: "none" } }}
        >
          <SharedFiles />
        </Grid>
        <Grid item sm={4} sx={{ display: { xs: "block", xl: "none" } }}>
          <ContactCards />
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
