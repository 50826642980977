import React, { useEffect, useState } from "react";
import useAuth from "./useAuth";
import axios from "axios.js";

const useComunications = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [comunications, setComunications] = useState([]);

  const getComs = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/backoffice/api/communication/");
      const allComs = response.data;
      let coms = [];
      allComs.forEach((com) => {
        com.recipients.forEach((recipient) => {
          if (recipient.id === user.id) {
            coms.push(com);
          }
        });
      });

      setComunications(coms);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getComs();
  }, []);
  return { loading, error, comunications };
};

export default useComunications;
