import { Box } from "@mui/material";

const baseStyles = {
	backgroundColor: "#DEDEDE",
	borderRadius: "10px",
	color: "#6A6A6A",
};

const Card = ({ children, extraStyles, ...others }) => {
	const styles = { ...baseStyles, ...extraStyles };
	return (
		<Box sx={styles} {...others}>
			{children}
		</Box>
	);
};

export default Card;
