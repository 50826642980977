import React from "react";
import { Box, Typography } from "@mui/material";
import { Scrollbar } from "app/components/yoi";
const GradientBox = ({ title, children }) => {
  return (
    <Box
      sx={{
        borderRadius: "10px",
        height: "100%",
        background: "rgba(0, 0, 0, 0.05)",
        boxShadow: "10px 10px 14px rgba(0, 0, 0, 0.19)",
      }}
    >
      <Box
        sx={{
          borderRadius: "10px 10px 0px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "54px",
          background:
            "linear-gradient(127.27deg, #F81E38 3.14%, #F81E38 19.22%, #7814E4 67.87%, #7814E4 86.16%)",
        }}
      >
        <Typography fontSize="14px" fontWeight={700} color="white">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Scrollbar
        sx={{
          maxHeight: "500px",
        }}
      >
        {children}
      </Scrollbar>
    </Box>
  );
};

export default GradientBox;
