import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getSuggestions,
	processSuggestionSocket,
} from "app/redux/states/metrics/metrics";
import { useSocketData } from "app/hooks";
import { SUGGESTION_CASES } from "app/consts/socketCases";

const { LIKED_SUGGESTION, COMMENTED_SUGGESTION, SHARED_SUGGESTION } =
	SUGGESTION_CASES;

const useSuggestions = () => {
	const dispatch = useDispatch();
	const queryParams = {
		refs: `${LIKED_SUGGESTION},${COMMENTED_SUGGESTION},${SHARED_SUGGESTION}`,
	};
	const suggestionMetrics = useSelector(
		(state) => state.metricsDetail.suggestions
	);
	const { lastJsonMessage, readyState } = useSocketData("/ws/metrics/", {
		queryParams,
	});
	const fetchSuggestionsData = () => {
		dispatch(getSuggestions());
	};

	useEffect(() => {
		if (suggestionMetrics.fulfilled) return;
		fetchSuggestionsData();
	}, []);

	useEffect(() => {
		if (readyState !== 1 || lastJsonMessage === null) return;
		dispatch(processSuggestionSocket(lastJsonMessage));
	}, [lastJsonMessage, readyState]);

	return {
		fetchSuggestionsData,
		suggestionMetrics,
	};
};

export default useSuggestions;
